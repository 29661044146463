<script>
/**
 * Product list view
 *
 * @property {Boolean} isFaulty
 * @property {Date} briefingReplyDate
 * @property {Date} briefingReplyDeadline
 * @property {Date} realisationReplyDate
 * @property {Date} realisationReplyDeadline
 * @property {Array | String} internalEditors
 * @property {Array | String} externalEditors
 * @property {Array | String} serviceProviders
 * @property {Boolean} userIsParticipant
 **/
import ContentHeader from '@/components/ContentHeader.vue'
import Table from '@/components/Table.vue'
import Icon from '@/components/Icon.vue'
import BadgeInput from '@/components/BadgeInput.vue'
import RouteLink from '@/components/RouteLink.vue'
import ResizableModalContainer from '@/components/ResizableModalContainer.vue'
import SmartSelect from '@/components/SmartSelect.vue'
import VerticalLine from '@/components/VerticalLine.vue'
import ProductServices from '@/views/products/product-portfolio/ProductServices.vue'
import ProductTasks from '@/views/products/product-portfolio/ProductTasks.vue'
import ProductDetails from '@/views/products/product-portfolio/ProductDetails.vue'
import ProductMembers from '@/views/products/product-portfolio/ProductMembers.vue'
import ProductContents from '@/views/products/product-portfolio/ProductContents.vue'
import ProductSchedule from '@/views/products/product-portfolio/ProductSchedule.vue'
import ProductAssetOrders from '@/views/products/product-portfolio/ProductAssetOrders.vue'
import ProductProcessHistory from '@/views/products/product-portfolio/ProductProcessHistory.vue'
import { axiosService } from '@/mixins/axiosService'
import { userHandler } from '@/mixins/userHandler'
import { dateTimeHelper } from '@/mixins/dateTimeHelper'

export default {
    name: 'ProductList',
    mixins: [
        axiosService,
        userHandler,
        dateTimeHelper
    ],
    components: {
        ContentHeader,
        Table,
        Icon,
        BadgeInput,
        RouteLink,
        ResizableModalContainer,
        SmartSelect,
        VerticalLine,
        ProductServices,
        ProductTasks,
        ProductDetails,
        ProductMembers,
        ProductContents,
        ProductSchedule,
        ProductAssetOrders,
        ProductProcessHistory
    },
    data () {
        return {
            productList: [],
            selectedProduct: {},
            widgets: [{
                label: 'Services & Prozesse',
                value: 'ProductServices'
            }, {
                label: 'Produktbezogene Aufgaben',
                value: 'ProductTasks'
            }, {
                label: 'Produktdaten',
                value: 'ProductDetails'
            }, {
                label: 'Mitgliederverwaltung',
                value: 'ProductMembers'
            }, {
                label: 'Bestandteile',
                value: 'ProductContents'
            }, {
                label: 'Zeitplan',
                value: 'ProductSchedule'
            }, {
                label: 'Assetbestellung',
                value: 'ProductAssetOrders'
            }, {
                label: 'Produkthistorie',
                value: 'ProductProcessHistory'
            }],
            productDetailsExpanded: false,
            productPublishedState: 'PUBLISHED',
            productManuallyFinishedState: 'FINISHED_MANUALLY',
            showSpinner: true,
            isAdminUser: this.getUserRoles().includes('admin')
        }
    },
    methods: {
        getProductList () {
            this.axiosGet(
                'products',
                this.$tc('productList.getProductListError'))
                .then(productList => {
                    productList.forEach(product => this.getProductInformation(product))
                    this.productList = productList
                    this.showSpinner = false
                })
                .catch(error => {
                    this.showSpinner = false
                    console.error(error)
                })
        },

        getProductInformation (product) {
            product.briefingDatePlannedOrActual = product.briefingReplyDate || product.briefingReplyDeadline
            product.briefingDateDisplayText = this.getBriefingDateText(product)
            product.dataDeliveryPlannedOrActual = product.realisationReplyDate || product.realisationReplyDeadline
            product.dataDeliveryDisplayText = this.getDataDeliveryText(product)
            product.releaseDatePlannedOrActual = this.isProductReleased(product)
                ? product.releaseDate
                : product.plannedReleaseDate
            product.statusTooltip = this.getStatusIconTooltip(product)
            product.releaseDateDisplayText = this.getReleaseDateText(product)
            product.productStateForTable = this.getProductStateForTable(product)
            product.userIsParticipantFilterText = this.getUserIsParticipantFilterText(product)
            const concatMembers = product.projectCoordinators.concat(product.internalEditors || []).concat(product.externalEditors || [])
            product.involved = [...new Set(concatMembers)]
        },

        appendProductToList (eventData) {
            const productId = eventData.detail
            this.axiosGet('products')
                .then(productList => {
                    const newProduct = productList.find(product => product.id === productId)
                    if (newProduct) {
                        this.getProductInformation(newProduct)
                        this.productList.push(newProduct)
                    }
                })
                .catch(() => {})
        },

        setSelected (rowClickEventData) {
            this.axiosGet(
                `product/${rowClickEventData.row.id}/state`,
                this.$tc('productList.getProductStateError'))
                .then(productInformation => {
                    if (!productInformation.userRights) {
                        productInformation.userRights = {}
                    }
                    this.selectedProduct = productInformation
                    setTimeout(() => {
                        this.setProductDetailsExpanded(true)
                    }, 50)
                })
                .catch(() => {})
        },

        setProductDetailsExpanded (expand) {
            this.productDetailsExpanded = expand
            if (expand === false) {
                setTimeout(() => {
                    this.selectedProduct = {}
                }, 200)
            }
        },

        productDoubleClicked (rowDoubleClickEventData) {
            const idOfClickedProduct = rowDoubleClickEventData.row.id
            this.$router.push({
                name: 'Product',
                params: {
                    productId: idOfClickedProduct
                }
            })
        },

        isProductReleased (product) {
            return !!product.releaseDate
        },

        isProductDevelopmentCompleted (product) {
            return product.productState === this.productPublishedState ||
                product.productState === this.productManuallyFinishedState
        },

        getBriefingDateText (product) {
            const plannedOrActual = product.briefingReplyDate
                ? this.$tc('generals.actual')
                : this.$tc('generals.planned')
            return product.briefingDatePlannedOrActual
                ? `${this.getFormattedShortDate(product.briefingDatePlannedOrActual, this.locale)} (${plannedOrActual})`
                : null
        },

        getDataDeliveryText (product) {
            const plannedOrActual = product.realisationReplyDate
                ? this.$tc('generals.actual')
                : this.$tc('generals.planned')
            return product.dataDeliveryPlannedOrActual
                ? `${this.getFormattedShortDate(product.dataDeliveryPlannedOrActual, this.locale)} (${plannedOrActual})`
                : null
        },

        getReleaseDateText (product) {
            const plannedOrActual = this.isProductReleased(product)
                ? this.$tc('generals.actual')
                : this.$tc('generals.planned')
            return product.releaseDatePlannedOrActual
                ? `${this.getFormattedShortDate(product.releaseDatePlannedOrActual, this.locale)} (${plannedOrActual})`
                : null
        },

        getMemberTooltip (product, member) {
            if (product.projectCoordinators && product.projectCoordinators.includes(member)) {
                return this.$tc('productList.table.projectCoordinator')
            }
            if (product.internalEditors && product.internalEditors.includes(member)) {
                return this.$tc('productList.table.internalEditor')
            }
            if (product.externalEditors && product.externalEditors.includes(member)) {
                return this.$tc('productList.table.externalEditor')
            }
            return null
        },

        getProductStateForTable (product) {
            return product.productState
                ? this.$tc(`generals.productStateValues.${product.productState}`)
                : this.$tc('generals.infoNotAvailable')
        },

        getUserIsParticipantFilterText (product) {
            return this.$tc(`productList.table.productFor${product.userIsParticipant ? 'Me' : 'Others'}`)
        },

        getStatusIconClass (product) {
            if (this.isProductDevelopmentCompleted(product)) {
                return 'fas fa-flag-checkered'
            }
            return product.isFaulty
                ? 'fas fa-minus-circle'
                : 'far fa-check-circle'
        },

        getStatusIconType (product) {
            if (this.isProductDevelopmentCompleted(product)) {
                return 'dark'
            }
            return product.isFaulty
                ? 'error'
                : 'success'
        },

        getStatusIconTooltip (product) {
            if (this.isProductDevelopmentCompleted(product)) {
                return this.$tc('productList.table.developmentCompleted')
            }
            return product.isFaulty
                ? this.$tc('productList.table.hasErrors')
                : this.$tc('productList.table.noErrors')
        },

        setSelectedWidget (selectChangeEventData) {
            const value = selectChangeEventData.option.value
            const userPreferences = this.getUserSettingsParameter('userPreferences')
            userPreferences.selectedWidget = value
            this.updateUserSettingsParameter('userPreferences', userPreferences)
        }
    },
    computed: {
        locale () {
            return this.$global.localization.locale
        },

        getTableConfig () {
            return [{
                key: 'status',
                label: null,
                filterable: false,
                sortable: false,
                alignment: 'left',
                exportable: false,
                width: 3,
                visible: true
            }, {
                key: 'shortTitle',
                label: this.$tc('productList.table.shortTitle'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 13,
                visible: true
            }, {
                key: 'productNumber',
                label: this.$tc('productList.table.productNumber'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 13,
                visible: true
            }, {
                key: 'isbn',
                sortKey: 'isbn',
                label: this.$tc('productList.table.isbn'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 10,
                visible: true
            }, {
                key: 'edition.name',
                label: this.$tc('productList.table.pspElement'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 10,
                visible: false
            }, {
                key: 'profitCenter',
                label: this.$tc('productList.table.profitCenter'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 10,
                visible: false
            }, {
                key: 'briefingDateDisplayText',
                sortKey: 'briefingDatePlannedOrActual',
                label: this.$tc('productList.table.briefingDate'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 9,
                visible: false
            }, {
                key: 'dataDeliveryDisplayText',
                sortKey: 'dataDeliveryPlannedOrActual',
                label: this.$tc('productList.table.dataDelivery'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 10,
                visible: true
            }, {
                key: 'releaseDateDisplayText',
                sortKey: 'releaseDatePlannedOrActual',
                label: this.$tc('productList.table.releaseDate'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 10,
                visible: true
            }, {
                key: 'projectCoordinators',
                label: this.$tc('productList.table.projectCoordinators'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 21,
                visible: true
            }, {
                key: 'involved',
                label: this.$tc('productList.table.involved'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 23,
                visible: false
            }, {
                key: 'serviceProviders',
                label: this.$tc('productList.table.serviceProviders'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 20,
                visible: false
            }, {
                key: 'productStateForTable',
                label: this.$tc('productList.table.productState'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 10,
                visible: true
            }, {
                key: 'action',
                filterKey: 'userIsParticipantFilterText',
                label: null,
                filterable: true,
                sortable: false,
                alignment: 'right',
                exportable: false,
                width: 10,
                visible: true
            }]
        },

        getHeaderCount () {
            if (!this.$refs.productListTable) {
                return this.productList.length
            }
            const visibleRowsCount = this.$refs.productListTable.getTableRows.length
            return visibleRowsCount !== this.productList.length
                ? `${visibleRowsCount}/${this.productList.length}`
                : this.productList.length
        },

        getSelectedWidget () {
            const userPreferences = this.getUserSettingsParameter('userPreferences')
            const option = this.widgets.find(widget => widget.value === userPreferences.selectedWidget)
            return option || this.widgets[0]
        }
    },
    watch: {
        '$global.localization.locale': {
            handler: function () {
                this.productList.forEach(product => {
                    this.getProductInformation(product)
                })
            },
            deep: true
        }
    },
    mounted () {
        this.getProductList()
        window.addEventListener('appendProduct', this.appendProductToList)
    },
    beforeUnmount () {
        window.removeEventListener('appendProduct', this.appendProductToList)
    }
}
</script>

<template>
    <div class="generals-container">
        <ContentHeader v-bind:header-title="$tc('productList.headline')"
                       v-bind:header-count="getHeaderCount"
                       v-bind:is-primary-header="true">
        </ContentHeader>
        <div class="product-list-container">
            <div class="product-list_table-container">
                <Table table-id="productList"
                       v-bind:table-config="getTableConfig"
                       v-bind:table-data="productList"
                       v-bind:filter-placeholder="$tc('productList.table.filterPlaceholder')"
                       v-bind:table-empty-message="$tc('productList.table.tableEmpty')"
                       v-bind:filter-no-results-message="$tc('productList.table.filterNoResults')"
                       default-sorting-key="releaseDatePlannedOrActual"
                       v-bind:selection-id="selectedProduct.id"
                       v-bind:highlight-selected=true
                       v-bind:read-only=false
                       v-bind:show-spinner=showSpinner
                       v-bind:custom-user-filter="true"
                       v-bind:allow-fullscreen="true"
                       @row-click="setSelected($event)"
                       @row-double-click="productDoubleClicked($event)"
                       ref="productListTable">
                    <template #cell(status)="data">
                        <Icon v-bind:tooltip="data.row.statusTooltip"
                              v-bind:icon-class="getStatusIconClass(data.row)"
                              v-bind:icon-large="isProductDevelopmentCompleted(data.row)"
                              v-bind:icon-type="getStatusIconType(data.row)">
                        </Icon>
                    </template>
                    <!-- shortTitle: default cell content -->
                    <!-- productNumber: default cell content -->
                    <!-- edition.name: default cell content -->
                    <!-- profitCenter: default cell content -->
                    <!-- briefingDateDisplayText: default cell content -->
                    <!-- dataDeliveryDisplayText: default cell content -->
                    <!-- releaseDateDisplayText: default cell content -->
                    <template #cell(projectCoordinators)="data">
                        <BadgeInput v-for="coordinator in data.row.projectCoordinators"
                                    v-bind:key="coordinator"
                                    v-bind:is-disabled=true
                                    v-bind:value="coordinator"
                                    class="product-list_table-badge">
                        </BadgeInput>
                    </template>
                    <template #cell(involved)="data">
                        <BadgeInput v-for="member in data.row.involved"
                                    v-bind:key="member"
                                    v-bind:is-disabled=true
                                    v-bind:value="member"
                                    v-bind:tooltip="getMemberTooltip(data.row, member)"
                                    class="product-list_table-badge">
                        </BadgeInput>
                    </template>
                    <template #cell(serviceProviders)="data">
                        <BadgeInput v-for="serviceProvider in data.row.serviceProviders"
                                    v-bind:key="serviceProvider"
                                    v-bind:is-disabled=true
                                    v-bind:value="serviceProvider"
                                    class="product-list_table-badge">
                        </BadgeInput>
                    </template>
                    <!-- productState: default cell content -->
                    <template #cell(action)="data">
                        <RouteLink v-bind:tooltip="$tc('productList.table.openProduct')"
                                   icon-class="fas fa-folder"
                                   hover-icon-class="fas fa-folder-open"
                                   v-bind:target="{name: 'Product', params: {productId: data.row.id}}">
                        </RouteLink>
                    </template>
                </Table>
            </div>
            <ResizableModalContainer v-if="selectedProduct.id"
                            v-bind:key="selectedProduct.id"
                            v-bind:is-expanded="productDetailsExpanded"
                            v-bind:modal-title="selectedProduct.productNumber"
                            v-bind:modal-subtitle="selectedProduct.shortTitle"
                            v-bind:modal-width-percent="75"
                            @modal-close="setProductDetailsExpanded(false)">
                <template v-slot>
                    <SmartSelect id="widget-select"
                                 class="product-list-modal-select"
                                 placeholder="Wähle dein Widget"
                                 v-bind:allow-input="false"
                                 v-bind:submit-button="false"
                                 v-bind:options="widgets"
                                 v-bind:default-option="getSelectedWidget"
                                 v-bind:sort-options="false"
                                 v-bind:filter-label-specifiers="['label']"
                                 v-bind:option-label-specifiers="['label']"
                                 @select-change="setSelectedWidget($event)">
                    </SmartSelect>
                    <div class="product-list-modal-route-link">
                        <VerticalLine></VerticalLine>
                        <RouteLink v-bind:tooltip="$tc('productList.table.openProduct')"
                                   icon-class="fas fa-folder"
                                   hover-icon-class="fas fa-folder-open"
                                   v-bind:target="{name: 'Product', params: {productId: selectedProduct.id}}">
                        </RouteLink>
                        <VerticalLine></VerticalLine>
                    </div>
                    <div class="product-list-modal-content-container">
                        <transition name="show">
                            <component v-bind:is="getSelectedWidget.value"
                                       v-bind:product-id="selectedProduct.id"
                                       v-bind:product-number="selectedProduct.productNumber"
                                       v-bind:product-state="selectedProduct">
                            </component>
                        </transition>
                    </div>
                </template>
            </ResizableModalContainer>
        </div>
    </div>
</template>

<style scoped lang="less">
.product-list-container {
    padding: var(--container-spacing);
    width: 100%;
    height: calc(100% - var(--primary-headline-height));

    .product-list_table-container {
        position: relative;
        float: left;
        width: 100%;
        height: 100%;

        .product-list-table-status-text {
            display: inline-block;

            &.m--success {
                color: var(--color-success);
            }

            &.m--error {
                color: var(--color-error);
            }
        }

        .product-list_table-badge {
            margin: 4px 4px 4px 0;
        }
    }

    .product-list-modal-select {
        position: absolute;
        top: var(--container-spacing);
        z-index: var(--z-index-modal-route-link);
        right: 110px;
    }

    .product-list-modal-route-link {
        position: absolute;
        right: calc(var(--container-spacing) + var(--icon-width));
        top: var(--container-spacing);
        z-index: var(--z-index-modal-route-link);
    }

    .product-list-modal-content-container {
        position: relative;
        width: 100%;
        height: 100%;
    }
}
</style>
