<script>

import EssentialSmartForm from '@/components/EssentialSmartForm.vue'
import { axiosService } from '@/mixins/axiosService'
import { taskExecutionHandler } from '@/mixins/taskExecutionHandler'
import { smartFormHelper } from '@/mixins/smartFormHelper'
import EssentialSmartFormService from '@/services/EssentialSmartFormService'
import { downloadHandler } from '@/mixins/downloadHandler'
import { productFileHandler } from '@/mixins/productFileHandler'
import { productLinkHandler } from '@/mixins/productLinkHandler'
import FilesAndLinks from '@/services/FilesAndLinks'
import { contentHelper } from '@/mixins/contentHelper'
import { regexHelper } from '@/mixins/regexHelper'
import FilesAndLinksUpload from '@/components/FilesAndLinksUpload.vue'
import { turnaroundTimesHandler } from '@/mixins/turnaroundTimesHandler'
import InteractionLink from '@/components/InteractionLink.vue'
import DateInput from '@/components/DateInput.vue'
import Icon from '@/components/Icon.vue'

export default {
    name: 'CustomTaskProcessDataWithoutCorrection',
    mixins: [
        axiosService,
        taskExecutionHandler,
        smartFormHelper,
        downloadHandler,
        productFileHandler,
        productLinkHandler,
        turnaroundTimesHandler,
        contentHelper,
        regexHelper
    ],
    components: {
        Icon,
        DateInput,
        InteractionLink,
        FilesAndLinksUpload,
        EssentialSmartForm
    },
    props: {
        taskData: Object
    },
    data () {
        return {
            task: this.taskData,
            customForm: null,
            formType: this.taskData.formKey,
            formExists: false,
            formIsValid: false,
            formWasSavedSuccessfully: true,
            essentialSmartFormService: null,
            listUpdateCounter: 0,
            config: {
                customFormKey: 'PROCESS_DATA_WITHOUT_CORRECTION', // used in a dictionary lookup in smartFormHelper mixin to identify this component as the right custom task
                getErrorMessage: 'task.taskViews.processDataWithoutCorrection.getFormError',
                updateErrorMessage: 'task.taskViews.processDataWithoutCorrection.updateFormError'
            },
            // input fields and their states
            states: {
                manuscriptUpload: {
                    slotName: 'field(manuscriptUpload)'
                }
            },
            areFilesAndLinksLoaded: false,
            areTurnaroundTimesLoaded: false,
            store: {
                turnaroundTimes: {
                }
            }
        }
    },
    methods: {
        async getCustomForm () {
            // Is called every time data comes from the back end
            function setupForm (appContext, form, formData) {
                appContext.applyValuesToFormConfigurationAndReturnChanges(null, formData.values, true, form, null, this)
                appContext.formExists = true
                appContext.customForm = form

                appContext.essentialSmartFormService = new EssentialSmartFormService(appContext.customForm, appContext.$global.localization.locale)
                appContext.addContentNameToHeadline()
            }

            const form = this.loadFormConfiguration(this.config.customFormKey)

            try {
                const formData = await this.axiosGet(`forms/search/${this.formType}/${this.task.productNumber}?productContentId=${this.task.productContentId}`)
                // search found something
                setupForm(this, form, formData)
            } catch (errorResponse) {
                if (errorResponse.status === 422) {
                    // search didn't find anything. Create a new form.
                    try {
                        const formData = await this.saveForm({
                            formConfig: form,
                            changes: {}
                        })
                        setupForm(this, form, formData)
                    } catch (error) {
                        console.error(error)
                    }
                } else {
                    this.addNotification({
                        type: 'error',
                        duration: 10000,
                        message: this.$tc(this.config.getErrorMessage)
                    })
                }
            }
        },

        async loadLinksAndFileReferences () {
            function setDynamicLists (data, fieldId, appContext) {
                appContext.setFormFieldValue(appContext.customForm, fieldId, data)
                appContext.updateFormIsValid()
            }
            function getFormFieldConfig (fieldId, appContext) {
                const formSection = appContext.customForm.sections.find(section => {
                    return section.fields.some(field => field.id === fieldId)
                })
                return formSection?.fields.find(field => field.id === fieldId)
            }
            function isMaterialField (key, appContext) {
                return !getFormFieldConfig(key, appContext)?.upload // if it's not an upload field, it's a field that shows data from a previous task
            }
            function isUploadField (appContext, key) {
                return getFormFieldConfig(appContext, key)?.upload
            }
            async function fetchMaxRoundInMetaData (appContext, productId, contentId) {
                let maxMaterialRound = 0
                for (const field of materialFields) {
                    const materialRound = await appContext.getRoundOfField(productId, contentId, field)
                    if (materialRound > maxMaterialRound) {
                        maxMaterialRound = materialRound
                    }
                }
                return maxMaterialRound
            }
            async function setMaterialDataForGivenRound (appContext, productId, contentId, maxMaterialRound) {
                for (const field of materialFields) {
                    const links = await appContext.getProductLinks(productId, contentId, field, maxMaterialRound)
                    const fileReferences = await appContext.getProductFiles(productId, contentId, field, maxMaterialRound)

                    setDynamicLists(FilesAndLinks.from(links, fileReferences).formFieldValue, field, appContext)
                }
            }
            async function setUploadDataForGivenRound (appContext, productId, contentId, currentRound) {
                for (const field of uploadFields) {
                    const links = await appContext.getProductLinks(productId, contentId, field, currentRound)
                    const fileReferences = await appContext.getProductFiles(productId, contentId, field, currentRound)

                    setDynamicLists(FilesAndLinks.from(links, fileReferences).formFieldValue, field, appContext)
                }
            }
            const materialFields = Object.keys(this.states).filter(key => isMaterialField(key, this))
            const uploadFields = Object.keys(this.states).filter(key => isUploadField(key, this))

            const maxMaterialRound = await fetchMaxRoundInMetaData(this, this.task.productId, this.task.productContentId)
            await setMaterialDataForGivenRound(this, this.task.productId, this.task.productContentId, maxMaterialRound)

            const currentRound = await this.getContentRound(this.task.productId, this.task.productContentId)
            await setUploadDataForGivenRound(this, this.task.productId, this.task.productContentId, currentRound)

            this.areFilesAndLinksLoaded = true
        },

        async loadTurnaroundTime () {
            const turnaroundTimeData = await this.fetchTurnaroundTimeData(
                this.task.productId,
                this.task.productContentId
            )
            console.debug('loadTurnaroundTime: ', turnaroundTimeData)
            if (turnaroundTimeData) {
                this.setTurnaroundTime(turnaroundTimeData.turnaroundTime)
                this.setPagesCount(turnaroundTimeData.pageCount)
                this.setTurnaroundTimeSetManually(turnaroundTimeData.wasSetManually)
            }

            this.areTurnaroundTimesLoaded = true
        },

        getTurnaroundTime () {
            return this.store.turnaroundTimes.turnaroundTime
        },
        setTurnaroundTime (turnaroundTime) {
            this.store.turnaroundTimes.turnaroundTime = turnaroundTime
        },
        setTurnaroundTimeSetManually (wasSetManually) {
            this.store.turnaroundTimes.wasSetManually = wasSetManually
        },
        getTurnaroundTimeSetManually () {
            return this.store.turnaroundTimes.wasSetManually
        },
        getPagesCount () {
            return this.store.turnaroundTimes.pageCount
        },
        setPagesCount (pageCount) {
            this.store.turnaroundTimes.pageCount = pageCount
        },

        setFormValid (isValid) {
            this.formIsValid = isValid
        },

        saveForm (formSubmitEventData) {
            return this.saveFormChanges(this.customForm, formSubmitEventData.changes, () => {}, false)
        },

        saveFormChanges (formConfig, changes, successCallback, doForceRender = true) {
            return this.saveSmartFormWithParameters(
                this.formType,
                `?productContentId=${this.task.productContentId}`,
                this.task.productNumber,
                formConfig,
                changes,
                this.formExists,
                this.$tc(this.config.updateErrorMessage))
                .then(formData => {
                    successCallback()
                    this.formWasSavedSuccessfully = true
                    if (doForceRender) this.forceListRender()
                    return Promise.resolve(formData)
                })
                .catch(() => {
                    this.formWasSavedSuccessfully = false
                    return Promise.reject(new Error())
                })
        },

        copyOfLinksAndFiles (fieldId) {
            const files = this.getFormFieldValue(this.customForm, fieldId)?.files || []
            this.attachTooltips(files)
            const links = this.getFormFieldValue(this.customForm, fieldId)?.links || []
            this.attachTooltips(links)

            return FilesAndLinks.from(links, files)
        },

        attachTooltips (listOfLinksOrFiles) {
            function tooltipFromMetadata (metadata) {
                return `${metadata.userName}\naus "${metadata.productContentName || ''}"`
            }

            for (const object of listOfLinksOrFiles) {
                object.tooltip = object.metadata ? tooltipFromMetadata(object.metadata) : ''
            }

            return listOfLinksOrFiles
        },
        getFieldLabel (field) {
            return this.essentialSmartFormService?.getFieldLabel(field)
        },

        addContentNameToHeadline () {
            const headline = this.essentialSmartFormService?.getTranslatedObjectProperty(this.customForm, 'headline')
            this.setFormHeadline(this.customForm, `${headline}: ${this.task.productContentName}`)
        },

        updateFormIsValid () {
            this.formIsValid = !this.essentialSmartFormService?.hasIncompleteSections()
        },
        forceListRender () {
            this.listUpdateCounter++
        },

        allMandatoryCheckboxFieldIdsCheckedTrue (listUpdateCounter) {
            const essentialSmartFormService = new EssentialSmartFormService(this.customForm, this.locale)
            return (listUpdateCounter + 1) && essentialSmartFormService.areAllCheckboxesSetTrue(['isDataAcceptanceConfirmed'])
        },

        // ----------------------------------------------------------------
        // Files and Link Upload Events
        // ----------------------------------------------------------------

        onUploadChange (changeEvent) {
            const { id, allLinksAndFiles } = changeEvent
            function updateDynamicLists (data, fieldId, appContext) {
                appContext.setFormFieldValue(appContext.customForm, fieldId, data)
            }

            const dynamicInputData = allLinksAndFiles.formFieldValue
            updateDynamicLists(dynamicInputData, id, this)
            this.saveFormChanges(this.customForm, { [id]: dynamicInputData }, () => {
                this.formExists = true
            })
            this.updateFormIsValid()
        }
    },
    computed: {
        locale () {
            return this.$global.localization.locale
        },

        isTaskCompletable () {
            return this.formIsValid && this.formWasSavedSuccessfully && this.allMandatoryCheckboxFieldIdsCheckedTrue(this.listUpdateCounter)
        }
    },
    watch: {
        isTaskCompletable (isCompletable) {
            this.$emit('task-completable-changed', {
                isCompletable: isCompletable
            })
        }
    },
    mounted () {
        // Note: some 'load*'s can run in parallel
        this.getCustomForm().then(() =>
            Promise.all([
                this.loadLinksAndFileReferences(),
                this.loadTurnaroundTime()
            ])
        )
    }
}
</script>

<template>
    <div class="custom-task-content-container">
        <EssentialSmartForm v-if="customForm"
                            id="customForm"
                            v-bind:hide-submit-button="true"
                            v-bind:form-config="customForm"
                            @form-change="saveForm($event)"
                            @form-submittable-change="setFormValid($event)">
            <template #field(manuscriptUpload)="data">
                <FilesAndLinksUpload v-if="areFilesAndLinksLoaded"
                                     :id="data.field.id"
                                     :label="getFieldLabel(data.field)"
                                     :initial-files-and-links="copyOfLinksAndFiles(data.field.id)"
                                     :download-file-action="(id) => this.downloadFileFromUrl(`product/file/${id}`)"
                                     :download-all-files-action="(productFileReferenceIds) => this.downloadAllFilesFromUrl(taskData.productId, productFileReferenceIds)"
                                     :key="`${data.field.id}_upload`"
                                     @input-changed="onUploadChange"
                >
                </FilesAndLinksUpload>
            </template>
            <template v-if="areTurnaroundTimesLoaded" #field(numberOfPagesToBeTypesetOrToFix)="data">
                <InteractionLink v-if="getTurnaroundTime()"
                                 v-bind:id="data.field.id"
                                 v-bind:label="getFieldLabel(data.field)"
                                 v-bind:value="getPagesCount()"
                                 v-bind:is-disabled="true">
                </InteractionLink>
            </template>
            <template v-if="areTurnaroundTimesLoaded" #field(processingDeadline)="data">
                <DateInput
                    v-if="getTurnaroundTime()"
                    :id="data.field.id"
                    :label="getFieldLabel(data.field)"
                    :default-value="getTurnaroundTime()"
                    :submit-button="false"
                    :is-disabled="true"
                >
                    <template #input-prefix>
                        <Icon class="custom-task-turnaround-time-icon" v-bind:icon-class="getTurnaroundTimeSetManually() ? 'fas fa-user-edit' : 'fas fa-calculator'"/>
                    </template>
                </DateInput>
            </template>
        </EssentialSmartForm>
    </div>
</template>

<style scoped lang="less">
.custom-task-content-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .custom-task-upload-files-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .custom-task-form-files {
            margin-bottom: var(--container-spacing);
        }
    }

    .custom-task-turnaround-time-icon {
        padding-left: 0;
    }
}
</style>
