<script>
/**
 * Task view
 *
 * @property {String} formJson
 **/
import EssentialSmartForm from '@/components/EssentialSmartForm.vue'
import { axiosService } from '@/mixins/axiosService'
import { taskExecutionHandler } from '@/mixins/taskExecutionHandler'
import { smartFormHelper } from '@/mixins/smartFormHelper'
import EssentialSmartFormService from '@/services/EssentialSmartFormService'
import { downloadHandler } from '@/mixins/downloadHandler'
import { productFileHandler } from '@/mixins/productFileHandler'
import { productLinkHandler } from '@/mixins/productLinkHandler'
import { turnaroundTimesHandler } from '@/mixins/turnaroundTimesHandler'
import { productContentHandler } from '@/mixins/productContentHandler'
import FilesAndLinks from '@/services/FilesAndLinks'
import { contentHelper } from '@/mixins/contentHelper'
import { regexHelper } from '@/mixins/regexHelper'
import { dateTimeHelper } from '@/mixins/dateTimeHelper'
import FilesAndLinksUpload from '@/components/FilesAndLinksUpload.vue'
import { customFormKeyHandler } from '@/mixins/customFormKeyHandler'
import { einreichungHelper } from '@/mixins/einreichungHelper'

export default {
    name: 'CustomTaskPerformEinreichung',
    mixins: [
        axiosService,
        taskExecutionHandler,
        smartFormHelper,
        downloadHandler,
        productFileHandler,
        productLinkHandler,
        turnaroundTimesHandler,
        productContentHandler,
        regexHelper,
        contentHelper,
        dateTimeHelper,
        customFormKeyHandler,
        einreichungHelper
    ],
    components: {
        EssentialSmartForm,
        FilesAndLinksUpload
    },
    props: {
        taskData: Object
    },
    data () {
        return {
            task: this.taskData,
            customForm: null,
            formType: this.componentKeyBy(this.taskData.formKey),
            formParameters: this.parametersFrom(this.taskData.formKey),
            formIsValid: false,
            formExists: false,
            formWasSavedSuccessfully: true,
            essentialSmartFormService: null,
            config: {
                customFormKey: 'PERFORM_EINREICHUNG', /** corresponds to src/mixins/smartFormHelper.js: Key to Forms Map: MANUSCRIPT_PROVISIONING: provideManuskriptSatzprozessForm **/
                getErrorMessage: 'task.taskViews.manuscript.getFormError',
                updateErrorMessage: 'task.taskViews.manuscript.updateFormError'
            },
            areFilesAndLinksLoaded: false,
            states: {
                einreichungUpload: {
                }
            }
        }
    },
    methods: {
        async getCustomForm () {
            // Is called every time data comes from the back end
            function setupForm (appContext, form, formData) {
                const parametersAsMap = appContext.asMap(['federalState', 'schoolType', 'resubmissionRound'], appContext.formParameters)
                formData.values.federalState = parametersAsMap.federalState
                formData.values.schoolType = parametersAsMap.schoolType
                // resubmissionRound is not needed in the form logic, but for telling apart data for each resubmission loop in the back end
                formData.values.resubmissionRound = parametersAsMap.resubmissionRound
                appContext.parseFederalStatesAndSchoolTypesKeyValuesToSmartFormFields(formData)
                formData.values.einreichungDeadlineDate = appContext.parseDateString(formData.values.einreichungDeadlineDate)
                formData.values.publishingDate = appContext.parseDateString(formData.values.publishingDate)
                appContext.applyValuesToFormConfigurationAndReturnChanges(null, formData.values, true, form, null, this)
                appContext.formExists = true
                appContext.customForm = form
                appContext.essentialSmartFormService = new EssentialSmartFormService(appContext.customForm, appContext.$global.localization.locale)
            }

            this.formTypeConfig = this.config[this.formType]
            const form = this.loadFormConfiguration(this.config.customFormKey)
            const urlParameters = this.getUrlParamsFromFormParameters(['federalState', 'schoolType', 'resubmissionRound'])
            try {
                const formData = await this.axiosGet(`forms/search/${this.formType}/${this.taskData.productNumber}?${urlParameters}`)
                // search found something
                setupForm(this, form, formData)
            } catch (errorResponse) {
                if (errorResponse.status === 422) {
                    // search didn't find anything. Create a new form.
                    try {
                        const formData = await this.saveForm({
                            formConfig: form,
                            changes: {}
                        })
                        setupForm(this, form, formData)
                    } catch (error) {
                        console.error(error)
                    }
                } else {
                    this.addNotification({
                        type: 'error',
                        duration: 10000,
                        message: this.$tc(this.config.getErrorMessage)
                    })
                }
            }
        },

        getUrlParamsFromFormParameters (parameterNames) {
            if (this.formParameters) {
                const parameterMap = this.asMap(parameterNames, this.formParameters)
                return `${this.toQueryParameters(parameterMap)}`
            } else {
                return ''
            }
        },

        async loadLinksAndFileReferences () {
            function setDynamicLists (appContext, data, fieldId) {
                appContext.setFormFieldValue(appContext.customForm, fieldId, data)
                appContext.updateFormIsValid()
            }

            async function setUploadData (appContext, productId, contentId, metadataMap) {
                const links = await appContext.getProductLinks(productId, contentId, 'einreichungUpload', metadataMap)
                const fileReferences = await appContext.getProductFilesByProductAndFieldIdAndMetadata(productId, 'einreichungUpload', metadataMap)

                setDynamicLists(appContext, FilesAndLinks.from(links, fileReferences).formFieldValue, 'einreichungUpload')
            }

            const metadataMap = this.asMap(['federalState', 'schoolType', 'resubmissionRound', 'currentRound'], this.formParameters)
            await setUploadData(this, this.task.productId, null, metadataMap)

            this.areFilesAndLinksLoaded = true
        },

        // -----------------------------------------
        // -- Form Handling
        // -----------------------------------------
        getFormField (fieldId) {
            return this.getFormFieldValue(this.customForm, fieldId)
        },

        setFormField (fieldId, data) {
            this.setFormFieldValue(this.customForm, fieldId, data)
        },

        setFormValid (isValid) {
            this.formIsValid = isValid
        },

        saveForm (formSubmitEventData) {
            return this.saveFormChanges(this.customForm, formSubmitEventData.changes, () => {}, false)
        },

        saveFormChanges (formConfig, changes, successCallback) {
            return this.saveSmartFormWithParameters(
                this.formType,
                this.asMap(['federalState', 'schoolType', 'resubmissionRound'], this.formParameters),
                this.taskData.productNumber,
                formConfig,
                changes,
                this.formExists,
                this.$tc(this.config.updateErrorMessage))
                .then(formData => {
                    successCallback()
                    this.formWasSavedSuccessfully = true
                    return Promise.resolve(formData)
                })
                .catch((e) => {
                    this.formWasSavedSuccessfully = false
                    return Promise.reject(e)
                })
        },

        async saveDataInFormAndRevalidate (id, dynamicInputData) {
            this.setFormField(id, dynamicInputData)
            await this.saveFormChanges(this.customForm, { [id]: dynamicInputData }, () => {
                this.formExists = true
            })
            this.updateFormIsValid()
        },

        copyOfLinksAndFiles (fieldId) {
            const formFieldValue = this.getFormField(fieldId)
            return FilesAndLinks.from(formFieldValue?.links || [], formFieldValue?.files || [])
        },
        getFieldLabel (field) {
            return this.essentialSmartFormService?.getFieldLabel(field)
        },

        addContentNameToHeadline () {
            const headline = this.essentialSmartFormService?.getTranslatedObjectProperty(this.customForm, 'headline')
            this.setFormHeadline(this.customForm, `${headline}: ${this.task.productContentName}`)
        },

        updateFormIsValid () {
            this.formIsValid = !this.essentialSmartFormService?.hasIncompleteSections()
        },

        // ----------------------------------------------------------------
        // Event Listeners
        // ----------------------------------------------------------------

        async onEinreichungUploadChange (changeEvent) {
            const { id, allLinksAndFiles } = changeEvent

            await this.saveDataInFormAndRevalidate(id, allLinksAndFiles.formFieldValue)
        }
    },
    computed: {
        isTaskCompletable () {
            return this.formIsValid && this.formWasSavedSuccessfully
        }
    },
    watch: {
        isTaskCompletable (isCompletable) {
            this.$emit('task-completable-changed', {
                isCompletable: isCompletable
            })
        }
    },
    mounted () {
        this.getCustomForm().then(this.loadLinksAndFileReferences)
    }
}
</script>

<template>
    <div class="custom-task-content-container">
        <EssentialSmartForm v-if="customForm"
                            id="customForm"
                            v-bind:hide-submit-button="true"
                            v-bind:form-config="customForm"
                            @form-change="saveForm($event)"
                            @form-submittable-change="setFormValid($event)">
            <template #field(einreichungUpload)="data">
                <FilesAndLinksUpload v-if="areFilesAndLinksLoaded"
                                     :id="data.field.id"
                                     :label="getFieldLabel(data.field)"
                                     :initial-files-and-links="copyOfLinksAndFiles(data.field.id)"
                                     :delete-file-action="deleteProductFile"
                                     :delete-link-action="deleteProductLink"
                                     :save-file-action="(file) => this.uploadProductFilesByProductAndFieldIdAndMetadata(
                                                                this.task.productId,
                                                                data.field.id,
                                                                file,
                                                                this.asMap(['federalState', 'schoolType', 'resubmissionRound'], this.formParameters)
                                                            )"
                                     :download-file-action="(id) => this.downloadFileFromUrl(`product/file/${id}`)"
                                     :download-all-files-action="(productFileReferenceIds) => this.downloadAllFilesFromUrl(taskData.productId, productFileReferenceIds)"
                                     @input-changed="onEinreichungUploadChange"
                >
                </FilesAndLinksUpload>
            </template>
        </EssentialSmartForm>
    </div>
</template>

<style scoped lang="less">
.custom-task-content-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.custom-task-content-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .custom-task-upload-files-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .custom-task-form-files {
            margin-bottom: var(--container-spacing);
        }
    }
}
</style>
