/**
 * A reusable service for the exchange of product resp. product content related files with the backend.
 **/
import { axiosService } from '@/mixins/axiosService'
import { notificationHandler } from '@/mixins/notificationHandler'

export const productFileHandler = {
    mixins: [
        axiosService,
        notificationHandler
    ],
    data () {
        return {
            basePathFile: 'product/file'
        }
    },
    methods: {
        getProductFiles (productId, contentId, fieldName, round) {
            const urlParameterString = `productId=${productId}&productContentId=${contentId}&formField=${fieldName}&round=${round}`
            return this.axiosGet(
                `${this.basePathFile}?${urlParameterString}`,
                this.$t('task.taskViews.files.fetchFilesError'))
        },
        getProductFilesByProductAndFieldIdAndMetadata(productId, fieldName, metadata) {
            const url = `${this.basePathFile}/metadata/${productId}`;
            const body = {
                formField: fieldName,
                federalState: metadata.federalState
            };
            return this.axiosPost(url, body, this.$t('task.taskViews.files.fetchFilesError'));
        },
        uploadProductFilesByIds (productId, contentId, fieldName, files) {
            const urlPath = `${productId}?productContentId=${contentId}&formField=${fieldName}`
            return this.uploadProductFiles(urlPath, files)
        },
        uploadProductFilesByProductAndFieldIdAndMetadata(productId, fieldName, files, metadata) {
            const uploadUrlPath = `metadata/upload/${productId}`
            const updatedMetadata = {
                ...metadata,
                formField: fieldName
            };

            return this.uploadProductFiles(uploadUrlPath, files, updatedMetadata)
        },

        uploadProductFiles(uploadUrlPath, files, updatedMetadata) {
            const formData = new FormData();

            files.forEach((file, index) => {
                formData.append('file', file);
            });

            Object.keys(updatedMetadata).forEach(key => {
                formData.append(key, updatedMetadata[key]);
            });

            return this.axiosPost(
                `${this.basePathFile}/${uploadUrlPath}`,
                formData,
                {
                    standard: this.$tc('task.taskViews.files.uploadFileSuccess.standard'),
                    short: this.$tc('task.taskViews.files.uploadFileSuccess.short')
                }
            );
        },
        deleteProductFile (fileId) {
            return this.axiosDelete(
                `${this.basePathFile}/${fileId}`,
                null,
                this.$t('task.taskViews.files.removeFileError'),
                {
                    standard: this.$t('task.taskViews.files.removeFileSuccess.standard'),
                    short: this.$tc('task.taskViews.files.removeFileSuccess.short')
                })
        }
    }
}
