<script>
/**
 * Custom Task view for Satzprozess - Satz Prüfen
 *
 * @property {String} formJson
 **/
import EssentialSmartForm from '@/components/EssentialSmartForm.vue'
import { axiosService } from '@/mixins/axiosService'
import { taskExecutionHandler } from '@/mixins/taskExecutionHandler'
import { smartFormHelper } from '@/mixins/smartFormHelper'
import { productFileHandler } from '@/mixins/productFileHandler'
import { productLinkHandler } from '@/mixins/productLinkHandler'
import FilesAndLinks from '@/services/FilesAndLinks'
import EssentialSmartFormService from '@/services/EssentialSmartFormService'
import { downloadHandler } from '@/mixins/downloadHandler'
import { regexHelper } from '@/mixins/regexHelper'
import { contentHelper } from '@/mixins/contentHelper'
import FilesAndLinksUpload from '@/components/FilesAndLinksUpload.vue'
import DateInput from '@/components/DateInput.vue'
import Icon from '@/components/Icon.vue'
import NumberInput from '@/components/NumberInput.vue'
import { turnaroundTimesHandler } from '@/mixins/turnaroundTimesHandler'
import { productContentHandler } from '@/mixins/productContentHandler'
import { dateTimeHelper } from '@/mixins/dateTimeHelper'

const FIELD_PROCESSING_DEADLINE = 'processingDeadline'
const FIELD_NUMBER_OF_PAGES_TO_FIX = 'numberOfPagesToFix'

const FIELD_RESULT_CHOICE = 'resultChoice'
const FIELD_RESULT_CHOICE_VALUE_NO_CORRECTIONS = 'noCorrectionsNeeded'

export default {
    name: 'CustomTaskTypesettingCheck',
    mixins: [
        axiosService,
        taskExecutionHandler,
        smartFormHelper,
        downloadHandler,
        productFileHandler,
        productLinkHandler,
        turnaroundTimesHandler,
        productContentHandler,
        regexHelper,
        contentHelper,
        dateTimeHelper
    ],
    components: {
        NumberInput,
        Icon,
        DateInput,
        FilesAndLinksUpload,
        EssentialSmartForm
    },
    props: {
        taskData: Object
    },
    data () {
        return {
            task: this.taskData,
            customForm: null,
            formType: this.taskData.formKey,
            formIsValid: false,
            formExists: false,
            formWasSavedSuccessfully: true,
            essentialSmartFormService: null,
            config: {
                customFormKey: 'TYPESETTING_CHECK', /** corresponds to src/mixins/smartFormHelper.js: Key to Forms Map: TYPESETTING_CHECK: checkTypesettingSatzprozessForm **/
                getErrorMessage: 'task.taskViews.typesettingCheck.getFormError',
                updateErrorMessage: 'task.taskViews.typesettingCheck.updateFormError'
            },
            states: {
                pagesForCorrection: {
                    slotName: 'field(pagesForCorrection)'
                },
                correctionInstructions: {
                    slotName: 'field(correctionInstructions)'
                },
                manuscriptUpload: {
                    slotName: 'field(manuscriptUpload)'
                },
                noCorrectionPagesLastRound: {
                    slotName: 'field(noCorrectionPagesLastRound)'
                },
                noCorrectionPages: {
                    slotName: 'field(noCorrectionPages)'
                }
            },
            areFilesAndLinksLoaded: false,
            isContentLoaded: false,
            areTurnaroundTimesLoaded: false,
            store: {
                productContent: null,
                turnaroundTimes: {
                    wasSetManually: false,
                    calculatedTurnaroundTime: null
                }
            }
        }
    },
    methods: {
        async getCustomForm () {
            // Is called every time data comes from the back end
            function setupForm (appContext, form, formData) {
                appContext.applyValuesToFormConfigurationAndReturnChanges(null, formData.values, true, form, null, this)
                appContext.formExists = true
                appContext.customForm = form

                appContext.essentialSmartFormService = new EssentialSmartFormService(appContext.customForm, appContext.$global.localization.locale)
                appContext.addContentNameToHeadline()
            }

            const form = this.loadFormConfiguration(this.config.customFormKey)

            try {
                const formData = await this.axiosGet(`forms/search/${this.formType}/${this.task.productNumber}?productContentId=${this.task.productContentId}`)
                // search found something
                setupForm(this, form, formData)
            } catch (errorResponse) {
                if (errorResponse.status === 422) {
                    // search didn't find anything. Create a new form.
                    try {
                        const formData = await this.saveForm({
                            formConfig: form,
                            changes: {}
                        })
                        setupForm(this, form, formData)
                    } catch (error) {
                        console.error(error)
                    }
                } else {
                    this.addNotification({
                        type: 'error',
                        duration: 10000,
                        message: this.$tc(this.config.getErrorMessage)
                    })
                }
            }
        },

        async loadLinksAndFileReferences () {
            function setDynamicLists (appContext, data, fieldId) {
                appContext.setFormFieldValue(appContext.customForm, fieldId, data)
                appContext.updateFormIsValid()
            }
            function getFormFieldConfig (appContext, fieldId) {
                const formSection = appContext.customForm.sections.find(section => {
                    return section.fields.some(field => field.id === fieldId)
                })
                return formSection?.fields.find(field => field.id === fieldId)
            }
            function isMaterialField (appContext, key) {
                return !getFormFieldConfig(appContext, key)?.upload // if it's not an upload field, it's a field that shows data from a previous task
            }
            function isUploadField (appContext, key) {
                return getFormFieldConfig(appContext, key)?.upload
            }
            async function fetchMaxRoundInMetaData (appContext, productId, contentId) {
                let maxMaterialRound = 0
                for (const field of materialFields) {
                    const materialRound = await appContext.getRoundOfField(productId, contentId, field)
                    if (materialRound > maxMaterialRound) {
                        maxMaterialRound = materialRound
                    }
                }
                return maxMaterialRound
            }
            async function setMaterialDataForGivenRound (appContext, productId, contentId, maxMaterialRound) {
                for (const field of materialFields) {
                    const links = await appContext.getProductLinks(productId, contentId, field, maxMaterialRound)
                    const fileReferences = await appContext.getProductFiles(productId, contentId, field, maxMaterialRound)

                    setDynamicLists(appContext, FilesAndLinks.from(links, fileReferences).formFieldValue, field)
                }
            }
            async function setUploadDataForGivenRound (appContext, productId, contentId, currentRound) {
                for (const field of uploadFields) {
                    const links = await appContext.getProductLinks(productId, contentId, field, currentRound)
                    const fileReferences = await appContext.getProductFiles(productId, contentId, field, currentRound)

                    setDynamicLists(appContext, FilesAndLinks.from(links, fileReferences).formFieldValue, field)
                }
            }
            const materialFields = Object.keys(this.states).filter(key => isMaterialField(this, key))
            const uploadFields = Object.keys(this.states).filter(key => isUploadField(this, key))

            const maxMaterialRound = await fetchMaxRoundInMetaData(this, this.task.productId, this.task.productContentId)
            await setMaterialDataForGivenRound(this, this.task.productId, this.task.productContentId, maxMaterialRound)

            const currentRound = await this.getContentRound(this.task.productId, this.task.productContentId)
            await setUploadDataForGivenRound(this, this.task.productId, this.task.productContentId, currentRound)

            this.areFilesAndLinksLoaded = true
        },

        // -----------------------------------------
        // -- Turnaround Times
        // -----------------------------------------
        async loadTurnaroundTime () {
            const pagesCount = this.getFormField(FIELD_NUMBER_OF_PAGES_TO_FIX) || this.getNumberOfProductContentPages() || 0
            const turnaroundTimeData = await this.fetchTurnaroundTimeDataByPageCount(
                this.task.productId,
                this.task.productContentId,
                pagesCount
            )
            console.debug('loadTurnaroundTime: ', turnaroundTimeData)
            this.setFormField(FIELD_PROCESSING_DEADLINE, turnaroundTimeData.turnaroundTime)
            this.setFormField(FIELD_NUMBER_OF_PAGES_TO_FIX, turnaroundTimeData.pageCount)
            this.updateFormIsValid()

            this.setTurnaroundTimeSetManually(turnaroundTimeData.wasSetManually)
            this.setCalculatedTurnaroundTime(turnaroundTimeData.calculatedTurnaroundTime)
            this.areTurnaroundTimesLoaded = true
        },

        getTurnaroundTime () {
            return this.getFormField(FIELD_PROCESSING_DEADLINE)
        },

        getTurnaroundTimeSetManually () {
            return this.store.turnaroundTimes.wasSetManually
        },
        setTurnaroundTimeSetManually (wasSetManually) {
            this.store.turnaroundTimes.wasSetManually = wasSetManually
        },
        getCalculatedTurnaroundTimeAsDate () {
            return this.store.turnaroundTimes.calculatedTurnaroundTime ? this.tryToParseToDate(this.store.turnaroundTimes.calculatedTurnaroundTime) : null
        },
        setCalculatedTurnaroundTime (turnaroundTime) {
            this.store.turnaroundTimes.calculatedTurnaroundTime = turnaroundTime
        },

        async loadProductContent () {
            this.store.productContent = await this.fetchProductContent(this.task.productId, this.task.productContentId)
            this.isContentLoaded = true
        },

        getProductContent () {
            return this.store.productContent || {}
        },
        getNumberOfProductContentPages () {
            return this.getProductContent().pages || null
        },
        getPagesCount () {
            return this.getFormField(FIELD_NUMBER_OF_PAGES_TO_FIX)
        },

        // -----------------------------------------
        // -- Form Handling
        // -----------------------------------------
        getFormField (fieldId) {
            return this.getFormFieldValue(this.customForm, fieldId)
        },

        setFormField (fieldId, data) {
            this.setFormFieldValue(this.customForm, fieldId, data)
        },

        async saveDataInFormAndRevalidate (id, dynamicInputData) {
            this.setFormField(id, dynamicInputData)
            await this.saveFormChanges(this.customForm, { [id]: dynamicInputData }, () => {
                this.formExists = true
            })
            this.updateFormIsValid()
        },

        setFormValid (isValid) {
            this.formIsValid = isValid
        },

        saveForm (formSubmitEventData) {
            return this.saveFormChanges(this.customForm, formSubmitEventData.changes, () => {})
        },

        saveFormChanges (formConfig, changes, successCallback) {
            return this.saveSmartFormWithParameters(
                this.formType,
                `?productContentId=${this.task.productContentId}`,
                this.task.productNumber,
                formConfig,
                changes,
                this.formExists,
                this.$tc(this.config.updateErrorMessage))
                .then(formData => {
                    successCallback()
                    this.formWasSavedSuccessfully = true
                    return Promise.resolve(formData)
                })
                .catch(() => {
                    this.formWasSavedSuccessfully = false
                    return Promise.reject(new Error())
                })
        },
        updateFormIsValid () {
            this.formIsValid = !this.essentialSmartFormService?.hasIncompleteSections()
        },
        addContentNameToHeadline () {
            const headline = this.essentialSmartFormService?.getTranslatedObjectProperty(this.customForm, 'headline')
            this.setFormHeadline(this.customForm, `${headline}: ${this.task.productContentName}`)
        },
        copyOfLinksAndFiles (fieldId) {
            const files = this.getFormFieldValue(this.customForm, fieldId)?.files || []
            this.attachTooltips(files)
            const links = this.getFormFieldValue(this.customForm, fieldId)?.links || []
            this.attachTooltips(links)

            return FilesAndLinks.from(links, files)
        },
        attachTooltips (listOfLinksOrFiles) {
            function tooltipFromMetadata (metadata) {
                return `${metadata.userName}\naus "${metadata.productContentName || ''}"`
            }

            for (const object of listOfLinksOrFiles) {
                object.tooltip = object.metadata ? tooltipFromMetadata(object.metadata) : ''
            }

            return listOfLinksOrFiles
        },
        getFieldLabel (field) {
            return this.essentialSmartFormService?.getFieldLabel(field)
        },

        // ----------------------------------------------------------------
        // Event Listeners
        // ----------------------------------------------------------------

        async onUploadChange (changeEvent) {
            const { id, allLinksAndFiles } = changeEvent

            await this.saveDataInFormAndRevalidate(id, allLinksAndFiles.formFieldValue)
        },

        async onChangeTurnaroundTime (event) {
            async function updateTurnaroundTime (appContext, wasSetManually, turnaroundTime) {
                const turnaroundTimeData = await appContext.updateTurnaroundTimeData(
                    appContext.task.productId,
                    appContext.task.productContentId,
                    {
                        pageCount: appContext.getPagesCount(),
                        wasSetManually: wasSetManually,
                        turnaroundTime
                    }
                )
                console.debug('Upsert => ', turnaroundTimeData)
                return turnaroundTimeData
            }

            const wasSetManually = true
            this.setTurnaroundTimeSetManually(wasSetManually)
            const turnaroundTimeData = await updateTurnaroundTime(this, wasSetManually, this.toBerlinDateAsISOString(event.value))

            this.setTurnaroundTimeSetManually(turnaroundTimeData.wasSetManually)
            this.setCalculatedTurnaroundTime(turnaroundTimeData.calculatedTurnaroundTime)
            await this.saveDataInFormAndRevalidate(FIELD_PROCESSING_DEADLINE, turnaroundTimeData.turnaroundTime)
        },

        async onChangePageCount (event) {
            async function updatePageCount (appContext, pageCount) {
                const turnaroundTimeData = await appContext.updateTurnaroundTimeData(
                    appContext.task.productId,
                    appContext.task.productContentId,
                    {
                        pageCount
                    }
                )
                console.debug('Upsert => ', turnaroundTimeData)
                return turnaroundTimeData
            }

            const turnaroundTimeData = await updatePageCount(this, event.value)

            this.setTurnaroundTimeSetManually(turnaroundTimeData.wasSetManually)
            this.setCalculatedTurnaroundTime(turnaroundTimeData.calculatedTurnaroundTime)
            this.setFormField(FIELD_PROCESSING_DEADLINE, turnaroundTimeData.turnaroundTime)
            await this.saveDataInFormAndRevalidate(FIELD_NUMBER_OF_PAGES_TO_FIX, event.value)
        }
    },
    computed: {
        isTaskCompletable () {
            return this.formIsValid && this.formWasSavedSuccessfully
        }
    },
    watch: {
        isTaskCompletable (isCompletable) {
            this.$emit('task-completable-changed', {
                isCompletable: isCompletable
            })
        }
    },
    beforeMount () {
        // Note: some 'load*'s can run in parallel
        this.getCustomForm().then(() =>
            Promise.all([
                this.loadLinksAndFileReferences(),
                this.loadProductContent()
            ]).then(() => {
                this.loadTurnaroundTime()
            })
        )
    },
    beforeUnmount () {
        if (this.getFormField(FIELD_RESULT_CHOICE) !== FIELD_RESULT_CHOICE_VALUE_NO_CORRECTIONS) {
            this.updateTurnaroundTimeData(
                this.task.productId,
                this.task.productContentId,
                {
                    pageCount: this.getPagesCount(),
                    wasSetManually: this.getTurnaroundTimeSetManually(),
                    turnaroundTime: this.getTurnaroundTime()
                }
            )
        }
    }
}
</script>

<template>
    <div class="custom-task-content-container">
        <EssentialSmartForm v-if="customForm"
                            id="customForm"
                            v-bind:hide-submit-button="true"
                            v-bind:form-config="customForm"
                            @form-change="saveForm($event)"
                            @form-submittable-change="setFormValid($event)">
            <template :key=key v-for="(configIterator, key) in states" #[configIterator.slotName]="data">
                <FilesAndLinksUpload v-if="areFilesAndLinksLoaded"
                                     :id="data.field.id"
                                     :label="getFieldLabel(data.field)"
                                     :initial-files-and-links="copyOfLinksAndFiles(data.field.id)"
                                     :delete-file-action="(data.field.deletable || data.field.upload) ? deleteProductFile : null"
                                     :delete-link-action="(data.field.deletable || data.field.upload) ? deleteProductLink : null"
                                     :save-file-action="data.field.upload ?
                                                            ((file) => this.uploadProductFilesByIds(
                                                                this.task.productId,
                                                                this.taskData.productContentId,
                                                                data.field.id,
                                                                file
                                                            )) : null"
                                     :save-link-action="data.field.upload ?
                                                            ((urlString) => this.saveProductLink({
                                                                productId: this.task.productId,
                                                                productContentId: this.task.productContentId,
                                                                formField: data.field.id,
                                                                url: urlString
                                                            })) : null"
                                     :download-file-action="(id) => this.downloadFileFromUrl(`product/file/${id}`)"
                                     :download-all-files-action="(productFileReferenceIds) => this.downloadAllFilesFromUrl(taskData.productId, productFileReferenceIds)"
                                     :key="`${data.field.id}_upload`"
                                     @input-changed="onUploadChange"
                >
                </FilesAndLinksUpload>
            </template>
            <template v-if="areTurnaroundTimesLoaded" #field(numberOfPagesToFix)="data">
                <NumberInput
                    :id="data.field.id"
                    :label="getFieldLabel(data.field)"
                    :minimum-value="0"
                    :maximum-value="getNumberOfProductContentPages()"
                    :default-value="getPagesCount()"
                    :placeholder="'' + getNumberOfProductContentPages()"
                    :submit-button="false"
                    @input-blurred="onChangePageCount"
                    @input-submit="onChangePageCount"
                />
            </template>
            <template v-if="areTurnaroundTimesLoaded" #field(processingDeadline)="data">
                <DateInput
                    :id="data.field.id"
                    :label="getFieldLabel(data.field)"
                    :default-value="getTurnaroundTime()"
                    :minimum-date="getCalculatedTurnaroundTimeAsDate()"
                    :submit-button="false"
                    @input-blurred="onChangeTurnaroundTime"
                    @input-submit="onChangeTurnaroundTime"
                >
                    <template #input-prefix>
                        <Icon v-bind:icon-class="getTurnaroundTimeSetManually() ? 'fas fa-user-edit' : 'fas fa-calculator'"/>
                    </template>
                </DateInput>
            </template>
        </EssentialSmartForm>
    </div>
</template>

<style scoped lang="less">
.custom-task-content-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}
</style>
