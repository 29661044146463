<script>
/**
 * Table is a pre styled and configurable table element with sticky headers and replaceable table definition elements.
 * It will throw a @row-click and @row-double-click event on either single or double click. There is a @row-expand event when opening the optional expandable content of the table.
 * - tableId (required): The id of the table. Must be provided if you want to use table footer or a customized first element, in order to generate unique slots. Also needs to be provided, if you use the custom user filter. Means... just always provide an id!
 * - tableConfig (required): List of objects with table header specific information. The following information should be passed:
 *      - key: Is necessary for proper filtering and sorting. Used for the definition of the replaceable slot. Should be a property of the tableData objects. Can be a nested property, too!
 *      - sortKey: Provide this information if you want to use a different property value for sorting. The property in 'key' will be the fallback value, if sortKey is not provided.
 *      - filterKey: Provide this information if you want to use a different property value for filtering. The property in 'key' will be the fallback value, if filterKey is not provided.
 *      - label: The label of the table column header.
 *      - filterable: Determines whether the column is filterable. If false, the values within the column will not be considered if the filter value changes. If no column is filterable, the filter will disappear.
 *      - sortable: Determines whether the column is sortable. If false the sorting icon next to the header value will disappear.
 *      - exportable: If at least one column is exportable, there will be an export button in the top left corner of the table. All rows will be exported to a csv file with each column that's flagged as exportable.
 *      - alignment: Determines the alignment of the content in the table row. Possible values are: left, center, right.
 *      - copyable: If this property is set to true and the default span content is used, the value will be copied to clipboard on click.
 *      - breakAnywhere: If texts should break anywhere if necessary; default: false.
 *      - width: The width of the column. All column widths should be 100 when added together.
 * - tableData (required): List of objects matching the tableConfig specification. Each tableData object will result in one table row. Each tableData object must have an id!
 * - filterPlaceholder (optional): Text shown if the filter input is empty.
 * - filterDisabled (default: false): If true, the filter will be temporarily disabled.
 * - defaultFilterValue (optional): If provided, the filter input will have this specific value. For redirections to filtered lists for example.
 * - defaultSortingKey (optional): If provided the table will be ordered by the specified field.
 * - defaultSortingDirection (optional): Determines whether the sorting is either ascending or descending. Default is asc.
 * - selectionId (optional): Enable to select an element from outside the component.
 * - tableEmptyMessage (optional): Hint message, shown if there are no table rows.
 * - filterNoResultsMessage (optional): Hint message, if all elements are filtered.
 * - showSpinner (default: false): While true, table placeholder will be a spinner.
 * - highlightSelected (default: false): Determines whether selected rows are highlighted.
 * - readOnly (default: false): Determines whether the table rows are clickable and have a pointer cursor.
 * - useCustomFirstElement (default: false): If true, the table will have a space over the actual table with a slot that can be replaced with the desired content. Slot will be named '${tableId}_custom-element'.
 * - useTableFooter (default: false): If true, the table will have a footer with a slot that can be replaced with the desired content. Slot will be named '${tableId}_footer'.
 * - isScrollable (default: true): If true the table is scrollable. If false the table wrapping element must scroll itself.
 * - customUserFilter (default: false): If true, there will be an additional button that will toggle the filter configuration. The personalized filter will be stored in the userPreferences using the tableId as key.
 * - expandableRows (default: false): If true, rows will be expandable by clicking the additional caret button. The content can be defined via a slot named 'expandable-content_${row.id}'.
 * - allowFullscreen (default: false): If true, there will be toggle button next to the quick filter for enabling and disabling fullscreen mode.
 *
 * @property {function} webkitFullscreenElement
 * @property {function} webkitExitFullscreen
 * @property {function} webkitRequestFullscreen
 **/
import Button from '@/components/Button.vue'
import TextInput from '@/components/TextInput.vue'
import SmartSelect from '@/components/SmartSelect.vue'
import BadgeInput from '@/components/BadgeInput.vue'
import IconButton from '@/components/IconButton.vue'
import HelpText from '@/components/HelpText.vue'
import { axiosService } from '@/mixins/axiosService'
import { userHandler } from '@/mixins/userHandler'
import { notificationHandler } from '@/mixins/notificationHandler'
import { downloadHandler } from '@/mixins/downloadHandler'
import { debounceHandler } from '@/mixins/debounceHandler'
import { copyHandler } from '@/mixins/copyHandler'
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'
import { dateTimeHelper } from '@/mixins/dateTimeHelper'
import ColumnConfigModal from '@/components/table/modal/ColumnConfigModal.vue'

export default {
    name: 'Table',
    mixins: [
        axiosService,
        userHandler,
        notificationHandler,
        downloadHandler,
        debounceHandler,
        copyHandler,
        dateTimeHelper
    ],
    components: {
        ColumnConfigModal,
        Button,
        TextInput,
        SmartSelect,
        BadgeInput,
        IconButton,
        HelpText,
        DynamicScroller,
        DynamicScrollerItem
    },
    // @row-click: Event emitted on row click | returns the event and clicked row
    // @row-double-click: Event emitted on row double click | returns the event and clicked row
    // @row-expand: Event emitted when opening expanded content | returns the row
    // Note: We do not declare the emits here, because we want to have the registrations as part of $attrs
    //       see https://github.com/vuejs/rfcs/discussions/397#discussioncomment-8967799 for details
    //       emits: ['row-click', 'row-double-click', 'row-expand'],
    props: {
        tableId: String,
        tableConfig: Array,
        // TODO: [PAU-2854] deprecated
        tableData: Array,
        filterPlaceholder: String,
        filterDisabled: {
            default: false,
            type: Boolean
        },
        defaultFilterValue: String,
        defaultSortingKey: String,
        defaultSortingDirection: String,
        selectionId: [String, Number],
        tableEmptyMessage: String,
        filterNoResultsMessage: String,
        showSpinner: {
            default: false,
            type: Boolean
        },
        highlightSelected: {
            default: false,
            type: Boolean
        },
        readOnly: {
            default: false,
            type: Boolean
        },
        useCustomFirstElement: {
            default: false,
            type: Boolean
        },
        useTableFooter: {
            default: false,
            type: Boolean
        },
        isScrollable: {
            default: true,
            type: Boolean
        },
        customUserFilter: {
            default: false,
            type: Boolean
        },
        expandableRows: {
            default: false,
            type: Boolean
        },
        allowFullscreen: {
            default: false,
            type: Boolean
        },
        allowManipulateColumns: {
            default: true,
            type: Boolean
        }
    },
    data () {
        return {
            filterableFields: this.tableConfig
                .filter(column => column.filterable)
                .map(column => column.filterKey || column.key),
            filterValue: this.defaultFilterValue || '',
            customUserFilterExpanded: false,
            sortBy: {
                field: null,
                direction: null
            },
            idsOfVisibleRows: [],
            currentSelectionId: this.selectionId,
            defaultSetId: 'set_default',
            expandColumnWidth: 50,
            showColumnManipulationModal: false,
            localUserTableManipulationSettings: []
        }
    },
    methods: {
        /* Get the value of a nested property in an object.
        / Example: field = edition.name, object = { id: '1', name: Test, edition: { name: Collectors Edition } }
        / returns 'Collectors Edition'
        */
        getValueByPropertyChain (field, object) {
            const propertyChain = field.split('.')
            const value = propertyChain.reduce((previous, current) => previous && previous[current], object)
            return !value || (Array.isArray(value) && value.length === 0)
                ? ''
                : value
        },

        getRowSizeDependencies (row) {
            const dependencies = []
            return this.getTableHeaders.forEach(column => {
                dependencies.push(row[column.key])
            })
        },

        getColumnWidth (column) {
            return `${column.width}%`
        },

        toggleFullScreen () {
            if (document.fullscreenElement || document.webkitFullscreenElement) {
                document.exitFullscreen
                    ? document.exitFullscreen()
                    : document.webkitExitFullscreen()
            } else {
                const tableWrapper = document.getElementById(`${this.tableId}-wrapper`)
                if (tableWrapper) {
                    tableWrapper.requestFullscreen
                        ? tableWrapper.requestFullscreen()
                        : tableWrapper.webkitRequestFullscreen()
                }
            }
        },

        updateVisibleRows (skipSorting) {
            const lowerCaseFilterValue = this.filterValue.toLowerCase()
            // if all fields are not filterable, return the whole list of elements
            // otherwise check which rows have a value that is filterable and contains the current filter value
            const filteredRows = this.filterableFields.length === 0
                ? this.tableData
                : this.tableData.filter(row => this.isRowVisible(row, lowerCaseFilterValue))

            if (!skipSorting && this.sortBy.field) {
                this.getSortedRows(filteredRows)
            }
            this.idsOfVisibleRows = filteredRows.map(row => row.id)
            return filteredRows
        },

        isRowVisible (row, lowerCaseFilterValue) {
            const customUserFilterFromSettings = this.getUserSettingsParameter(this.tableId)
            const loopState = {
                fulfillsQuickFilter: false, // Must become true for any filterable column. Once it's true the condition is fulfilled
                fulfillsUserFilter: false // Must become true for every filterable column. Recalculated for each column.
            }
            for (const element of this.filterableFields) {
                this.checkIfFieldsMatchFilter(element, row, loopState, customUserFilterFromSettings, lowerCaseFilterValue)
                if (!loopState.fulfillsUserFilter) {
                    break
                }
            }
            return loopState.fulfillsQuickFilter && loopState.fulfillsUserFilter
        },

        checkIfFieldsMatchFilter (filterField, row, loopState, customUserFilterFromSettings, lowerCaseFilterValue) {
            let compareValues = this.getValueByPropertyChain(filterField, row)
            if (!Array.isArray(compareValues)) {
                compareValues = [compareValues]
            }

            // Recalculate the fulfillment of the user filter
            loopState.fulfillsUserFilter = !this.customUserFilter ||
                !customUserFilterFromSettings[filterField] ||
                customUserFilterFromSettings[filterField].length === 0

            // No need to check the values of this column if both conditions are yet fulfilled, because it won't change anything.
            if (!loopState.fulfillsQuickFilter || !loopState.fulfillsUserFilter) {
                compareValues.forEach(compareValue => {
                    const generalizedValue = compareValue.toString().toLowerCase()
                    if (!loopState.fulfillsQuickFilter) {
                        loopState.fulfillsQuickFilter = generalizedValue.includes(lowerCaseFilterValue)
                    }
                    if (!loopState.fulfillsUserFilter) {
                        loopState.fulfillsUserFilter = customUserFilterFromSettings[filterField].some(customUserFilterValue => {
                            return this.valueMatchesFilter(customUserFilterValue, compareValues, generalizedValue)
                        })
                    }
                })
            }
        },

        valueMatchesFilter (customUserFilterValue, compareValues, generalizedValue) {
            const generalizedFilterValue = customUserFilterValue.toString()
            if (generalizedFilterValue.startsWith('not:')) {
                const filterValues = generalizedFilterValue.replace('not:', '').split(':&:')
                return compareValues.every(value => {
                    value = value.toString().toLowerCase()
                    return filterValues.every(filterValue => !value.includes(filterValue))
                })
            }
            // By using 'only:' all cells with multiple values will be ignored by the filter value
            if (generalizedFilterValue.startsWith('only:')) {
                return generalizedValue.includes(generalizedFilterValue.replace('only:', '')) &&
                    compareValues.length === 1
            }
            if (generalizedFilterValue.startsWith('<:')) {
                return generalizedValue < generalizedFilterValue.replace('<:', '')
            }
            if (generalizedFilterValue.startsWith('>:')) {
                return generalizedValue > generalizedFilterValue.replace('>:', '')
            }
            const filterValues = generalizedFilterValue.split(':&:')
            return filterValues.every(
                filterValue => compareValues.some(value => value.toString().toLowerCase().includes(filterValue))
            )
        },

        filterChanged (inputSubmitEventData) {
            this.filterValue = inputSubmitEventData.value
        },

        getSortedRows (rows) {
            const sortConfig = this.sortBy
            rows.sort(function (a, b) {
                let valueA = this.getValueByPropertyChain(sortConfig.field, a)
                let valueB = this.getValueByPropertyChain(sortConfig.field, b)

                if (valueA === valueB) {
                    valueA = a.id
                    valueB = b.id
                }

                const comparisonResult = valueA.toString().localeCompare(
                    valueB.toString(),
                    undefined,
                    { numeric: true, sensitivity: 'base' })
                return sortConfig.direction === 'asc'
                    ? comparisonResult
                    : -comparisonResult
            }.bind(this))
        },

        configureSorting () {
            const customSorting = this.getUserSettingsParameter(`${this.tableId}-sort`)
            const tableHeaders = this.getTableHeaders
            if (customSorting &&
                customSorting.field &&
                tableHeaders.some(column => column.sortKey === customSorting.field || column.key === customSorting.field)) {
                this.sortBy = customSorting
            } else {
                if (this.defaultSortingKey) {
                    this.sortBy.field = this.defaultSortingKey
                } else {
                    const firstSortableColumn = tableHeaders.find(column => column.sortable)
                    this.sortBy.field = firstSortableColumn
                        ? firstSortableColumn.sortKey || firstSortableColumn.key
                        : null
                }
                this.sortBy.direction = this.defaultSortingDirection || 'asc'
            }
        },

        sortingChanged (column) {
            if (!column.sortable) {
                return
            }

            const columnSortKey = column.sortKey || column.key
            if (this.sortBy.field === columnSortKey) {
                this.sortBy.direction = (this.sortBy.direction === 'asc') ? 'desc' : 'asc'
            } else {
                this.sortBy.field = columnSortKey
                this.sortBy.direction = 'asc'
            }
            this.updateUserSettingsParameter(
                `${this.tableId}-sort`,
                this.sortBy,
                this.$t('table.changeSortingError')
            )
        },

        getCustomFilterOptions (column) {
            const customUserFilter = this.getUserSettingsParameter(this.tableId)
            const options = []
            this.tableData.forEach(row => {
                const columnFilterKey = column.filterKey || column.key
                let fieldValues = this.getValueByPropertyChain(columnFilterKey, row)
                if (!Array.isArray(fieldValues)) {
                    fieldValues = [fieldValues]
                }

                fieldValues = fieldValues.filter(fieldValue => fieldValue !== '')
                fieldValues.forEach(fieldValue => {
                    const optionsIncludeValue = options.some(option => option.value === fieldValue)
                    const filterIncludesValue = customUserFilter[columnFilterKey] &&
                        customUserFilter[columnFilterKey].includes(fieldValue.toString().toLowerCase())

                    if (!optionsIncludeValue && !filterIncludesValue) {
                        options.push({
                            value: fieldValue
                        })
                    }
                })
            })
            return options
        },

        toggleCustomFilterConfig () {
            this.customUserFilterExpanded = !this.customUserFilterExpanded
        },

        getCustomFilterValues (column) {
            const customUserFilter = this.getUserSettingsParameter(this.tableId)
            const columnFilterKey = column.filterKey || column.key
            return customUserFilter[columnFilterKey] || []
        },

        addCustomFilterValueToColumn (selectSubmitEventData, column) {
            const customUserFilter = this.getUserSettingsParameter(this.tableId)
            const columnFilterKey = column.filterKey || column.key
            if (!customUserFilter[columnFilterKey]) {
                customUserFilter[columnFilterKey] = []
            }
            const addedFilterValues = []
            const newFilterValues = selectSubmitEventData.option.value
                .toString()
                .toLowerCase()
                .split(':or:')

            newFilterValues.forEach(newFilterValue => {
                if (!customUserFilter[columnFilterKey].includes(newFilterValue)) {
                    customUserFilter[columnFilterKey].push(newFilterValue)
                    addedFilterValues.push(newFilterValue)
                }
            })

            if (addedFilterValues.length > 0) {
                const isSingleValue = addedFilterValues.length === 1
                const errorMessage = isSingleValue
                    ? this.$t('table.addUserFilterError', [addedFilterValues[0]])
                    : this.$t('table.addUserFiltersError', [addedFilterValues.join(',<br />')])
                const standardMessage = isSingleValue
                    ? this.$t('table.addUserFilterSuccess.standard', [addedFilterValues[0]])
                    : this.$t('table.addUserFiltersSuccess.standard', [addedFilterValues.join(',<br />')])
                const shortMessage = isSingleValue
                    ? this.$t('table.addUserFilterSuccess.short', [addedFilterValues[0]])
                    : this.$t('table.addUserFiltersSuccess.short', [addedFilterValues.length])

                this.updateUserSettingsParameter(
                    this.tableId,
                    customUserFilter,
                    errorMessage,
                    {
                        standard: standardMessage,
                        short: shortMessage
                    })
            }
            this.$forceUpdate()
        },

        removeCustomFilterValueFromColumnByIndex (index, column) {
            const customUserFilter = this.getUserSettingsParameter(this.tableId)
            const columnFilterKey = column.filterKey || column.key
            const deletedFilter = customUserFilter[columnFilterKey].splice(index, 1)
            this.updateUserSettingsParameter(
                this.tableId,
                customUserFilter,
                this.$t('table.removeUserFilterError', [deletedFilter]),
                {
                    standard: this.$t('table.removeUserFilterSuccess.standard', [deletedFilter]),
                    short: this.$t('table.removeUserFilterSuccess.short', [deletedFilter])
                })
        },

        getSortClass (column) {
            if (!column.sortable) {
                return ''
            }
            const columnSortKey = column.sortKey || column.key
            if (columnSortKey !== this.sortBy.field) {
                return 'm--sort'
            }
            return `m--sort-${this.sortBy.direction}`
        },

        isSelected (row) {
            return this.currentSelectionId === row.id
        },

        isHighlighted (row) {
            return this.highlightSelected && this.isSelected(row)
        },

        isRowExpanded (row) {
            return row.expanded
        },

        isRowDisabled (row) {
            return row.isDisabled
        },

        setRowExpanded (row) {
            row.expanded = !row.expanded
            if (this.isRowExpanded(row)) {
                this.$emit('row-expand', {
                    row: row
                })
            }
            this.$forceUpdate()
        },

        setSelected (event, row) {
            // Detect, if table uses double click event for immediate or delayed execution
            const delay = this.$attrs.onRowDoubleClick
                ? 250
                : 0
            this.isDelayedExecutionRunning()
                ? this.clearDelayedExecutionTimeout()
                : this.delayedExecution(() => {
                    if (!this.readOnly && !this.isSelected(row)) {
                        this.currentSelectionId = row.id
                    }
                    this.emitClick(event, row)
                }, delay)
        },

        isTableEmpty () {
            return this.tableData.length === 0 || this.updateVisibleRows(true).length === 0
        },

        emitClick (event, row) {
            this.$emit('row-click', {
                event: event,
                row: row
            })
        },

        emitDoubleClick (event, row) {
            this.$emit('row-double-click', {
                event: event,
                row: row
            })
        },

        exportVisibleRowsAsCsv () {
            // MS Excel does not recognize UTF-8 as the format by default (yes, really), let's add the BOM to nudge it to do the right thing.
            const universalBOM = '\uFEFF'
            const csvContent = this.getCsvContent()
            this.saveToFileSystem(
                'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + csvContent),
                `${this.tableId}_${this.getFormattedShortDate(this.getNowDate(), this.locale)}.csv`
            )
        },

        getCsvContent () {
            return this.getCsvAsString(this.exportableFields, this.getTableRows, this.getValueByPropertyChain)
        },

        getCsvAsString (exportableTableFields, tableRows, getValue) {
            function quotedForCsv (rawValue) {
                const stringifiedValue = `${rawValue}`

                // * Quote fields
                // * Remove line breaks altogether, MS Excel does not seem to recognize them correctly, no matter what we do
                return `"${stringifiedValue.replace('"', '\\"').replace('\n', ' ').replace('\r', ' ')}"`
            }
            const headers = exportableTableFields.map(column => quotedForCsv(column.label))
            headers.unshift(quotedForCsv('Id'))
            const csvRows = [
                headers
            ]
            tableRows.forEach(row => {
                const csvRow = []
                csvRow.push(quotedForCsv(row.id))
                exportableTableFields.forEach(field => {
                    const value = field.key === 'itemType'
                        ? getValue('itemTypeTooltip', row)
                        : getValue(field.key, row)
                    Array.isArray(value)
                        ? csvRow.push(quotedForCsv(value.join(' | ')))
                        : csvRow.push(quotedForCsv(value))
                })
                csvRows.push(csvRow)
            })
            return csvRows.map(row => row.join(';')).join('\n')
        },

        copyToClipboard (event, value) {
            event.stopPropagation()
            this.copyValueToClipboard(value, true)
        },

        openColumnManipulationDialog () {
            this.showColumnManipulationModal = !this.showColumnManipulationModal
        },

        updateLocalUserTableManipulationSettings (settings) {
            this.localUserTableManipulationSettings = settings
            this.$emit('column-visibility-change', settings)
        }
    },
    computed: {
        locale () {
            return this.$global.localization.locale
        },

        getTableHeaders () {
            if (!this.localUserTableManipulationSettings) return []

            const filteredColumns = this.tableConfig.filter(column => {
                const setting = this.localUserTableManipulationSettings.find(c => c.key === column.key)
                if (!setting) {
                    this.localUserTableManipulationSettings.push({ key: column.key, visible: true })
                    return true
                }

                if (setting.visible === undefined) {
                    setting.visible = true
                }

                if (!setting.visible) return false

                // Ensure column has a width greater than 0
                return column.width > 0
            })

            // Sort columns based on the order in `localUserTableManipulationSettings`
            filteredColumns.sort((a, b) => {
                const indexA = this.localUserTableManipulationSettings.findIndex(c => c.key === a.key)
                const indexB = this.localUserTableManipulationSettings.findIndex(c => c.key === b.key)
                return indexA - indexB
            })

            return filteredColumns
        },

        getTableRows () {
            return this.updateVisibleRows()
        },

        getCustomFilterCount () {
            const customUserFilter = this.getUserSettingsParameter(this.tableId)
            let counter = 0
            this.filterableFields.forEach(columnKey => {
                if (customUserFilter[columnKey]) {
                    counter += customUserFilter[columnKey].length
                }
            })
            return counter
        },

        exportableFields () {
            return this.getTableHeaders.filter(column => column.exportable)
        },

        getTableManipulationSettingsKey () {
            return 'TableManipulationSettings_' + this.tableId
        },

        getUserTableManipulationSettings () {
            try {
                const userSettings = this.getUserSettingsParameter(this.getTableManipulationSettingsKey)
                return Array.isArray(userSettings)
                    ? userSettings
                    : this.tableConfig.map(column => ({
                        key: column.key,
                        visible: column.visible
                    }))
            } catch (error) {
                console.error('Error parsing settings:', error)
                return this.tableConfig.map(column => ({ key: column.key, visible: column.visible }))
            }
        }
    },
    beforeMount () {
        this.configureSorting()
    },
    created () {
        this.localUserTableManipulationSettings = this.getUserTableManipulationSettings || []
    },
    watch: {
        selectionId () {
            this.currentSelectionId = this.selectionId
        },
        defaultFilterValue () {
            this.filterValue = this.defaultFilterValue || ''
        }
    }
}
</script>

<template>
    <div class="c_table-wrapper"
         v-bind:id="`${tableId}-wrapper`">
        <div v-if="filterableFields.length > 0 || exportableFields.length > 0 || allowFullscreen"
             class="c_table-addon-wrapper">
            <div class="c_table-addons">
                <IconButton v-if="allowFullscreen"
                            v-bind:font-size="24"
                            class="c_table-full-screen-toggle"
                            icon-class="fas fa-expand-alt"
                            v-bind:tooltip="$tc('table.toggleFullscreen')"
                            @button-submit="toggleFullScreen()">
                </IconButton>
                <IconButton v-if="exportableFields.length > 0"
                            class="c_table-excel-generate-button"
                            v-bind:tooltip="$tc('table.exportVisibleRows')"
                            icon-class="fas fa-file-csv"
                            v-bind:font-size="24"
                            @button-submit="exportVisibleRowsAsCsv()">
                </IconButton>
                <IconButton v-if="allowManipulateColumns"
                            v-bind:font-size="24"
                            class="c_table-manipulate-columns-button"
                            icon-class="fas fa-table"
                            v-bind:tooltip="$t('table.tableManipulationModal.title')"
                            @button-submit="openColumnManipulationDialog()">
                </IconButton>
                <TextInput v-if="filterableFields.length > 0"
                           v-bind:id="`${tableId}_quick-filter`"
                           class="c_table-filter-text"
                           icon-class="fas fa-filter"
                           v-bind:is-disabled="filterDisabled"
                           v-bind:submit-button=false
                           v-bind:default-value="defaultFilterValue"
                           v-bind:placeholder="filterPlaceholder || $tc('table.defaultFilterPlaceholder')"
                           @input-change="filterChanged($event)">
                </TextInput>
                <Button v-if="customUserFilter"
                        class="c_table-filter-button"
                        v-bind:is-disabled="filterDisabled"
                        button-type="cancel"
                        @button-submit="toggleCustomFilterConfig()">
                    <template v-slot>
                        <span>{{ customUserFilterExpanded ? $tc('table.closeFilterButtonText') : $tc('table.openFilterButtonText') }}
                            <span v-if="getCustomFilterCount > 0"> ({{ getCustomFilterCount }})</span>
                        </span>
                    </template>
                </Button>
                <HelpText v-if="customUserFilter"
                          class="c_table-filter-container"
                          v-bind:text="$tc('table.customFilterHelpText')"
                          v-bind:width-px="800"
                          position="center">
                </HelpText>
            </div>
        </div>
        <table class="c_table"><tbody>
            <tr class="c_table-row">
                <th v-for="column in getTableHeaders"
                    class="c_table-th generals-text-ellipsis"
                    v-bind:style="{width: getColumnWidth(column)}"
                    v-bind:key="column.key"
                    v-on:click="sortingChanged(column)"
                    v-bind:class="getSortClass(column)">
                    <span v-bind:title="column.tooltip? column.tooltip : column.label">{{ column.label }}</span>
                </th>
                <th v-if="expandableRows"
                    class="c_table-th"
                    v-bind:style="{width: `${expandColumnWidth}px`}">
                </th>
            </tr>
        </tbody></table>
        <div class="c_body-table-wrapper"
             v-bind:class="{'m--filterable': filterableFields.length > 0}">
            <div v-if="customUserFilter && !filterDisabled"
                 class="c_table-filter-configuration-wrapper generals-animate"
                 v-bind:class="{'m--expanded': customUserFilterExpanded}">
                <div v-for="column in getTableHeaders"
                     class="c_table-filter-configuration-container"
                     v-bind:style="{width: getColumnWidth(column)}"
                     v-bind:key="column.key">
                    <div v-if="!column.filterable"
                         class="c_table-filter-configuration m--disabled">
                    </div>
                    <div v-else class="c_table-filter-configuration">
                        <div class="c_table-filter-configuration-header">
                            <SmartSelect class="c_table-filter-configuration-header-select"
                                         v-bind:id="`${tableId}_${column.key}_filter-input`"
                                         v-bind:submit-button=true
                                         v-bind:placeholder="$t('table.filterConfigPlaceholder', [column.label]).toString()"
                                         v-bind:options="getCustomFilterOptions(column)"
                                         v-bind:sort-options=true
                                         v-bind:option-label-specifiers="['value']"
                                         v-bind:filter-label-specifiers="['value']"
                                         v-bind:is-type-ahead=true
                                         v-bind:allow-input=true
                                         v-bind:show-unsaved-changes=false
                                         v-bind:clear-input-on-submit=true
                                         @select-submit="addCustomFilterValueToColumn($event, column)">
                            </SmartSelect>
                        </div>
                        <div class="c_table-filter-configuration-body">
                            <transition-group name="fade">
                                <BadgeInput v-for="(filterValue, index) in getCustomFilterValues(column)"
                                            v-bind:key="filterValue"
                                            class="c_table-filter-configuration-body-badge"
                                            v-bind:value="filterValue"
                                            @badge-submit="removeCustomFilterValueFromColumnByIndex(index, column)">
                                </BadgeInput>
                            </transition-group>
                        </div>
                    </div>
                </div>
                <div v-if="expandableRows"
                     class="c_table-filter-configuration-container"
                     v-bind:style="{width: `${expandColumnWidth}px`}">
                    <div class="c_table-filter-configuration m--disabled"></div>
                </div>
            </div>
            <header v-if="useCustomFirstElement"
                    class="c_body-table-custom-element">
                <slot v-bind:name="`${tableId}_custom-element`"></slot>
            </header>
            <table v-bind:id="tableId"
                   class="c_body-table"
                   v-bind:class="{'m--scrollable': isScrollable}"><tbody>
                <tr v-if="isTableEmpty() && !useTableFooter">
                    <td class="c_body-table-placeholder">
                        <div v-if="showSpinner">
                            <span class="c_body-table-placeholder-spinner fas fa-circle-notch fa-spin"></span>
                            <span> {{$tc('table.spinnerText')}}</span>
                            <span class="generals-walking-dots"></span>
                        </div>
                        <span v-else>{{tableData.length === 0 ?
                            tableEmptyMessage || $tc('table.defaultTableEmpty'):
                            filterNoResultsMessage || $tc('table.defaultFilterNoResults')}}</span>
                    </td>
                </tr>
                <DynamicScroller v-else
                                 class="c_body-table-scroll-container generals-animate"
                                 v-bind:class="{'m--filter-expanded': customUserFilterExpanded}"
                                 v-bind:items="getTableRows"
                                 v-bind:buffer="800"
                                 v-bind:min-item-size="50">
                    <template v-slot="{ item, index, active }">
                        <DynamicScrollerItem
                            v-bind:item="item"
                            v-bind:active="active"
                            v-bind:size-dependencies="getRowSizeDependencies(item)"
                            v-bind:data-index="index">
                            <tr v-if="idsOfVisibleRows.includes(item.id)"
                                v-bind:id="`table-row_${item.id}`"
                                class="c_body-table-row"
                                v-bind:class="{
                                    'm--readOnly': readOnly,
                                    'm--selected': isHighlighted(item),
                                    'm--expanded': isRowExpanded(item),
                                    'm--disabled': isRowDisabled(item)
                                }"
                                v-on:click="setSelected($event, item)"
                                v-on:dblclick="emitDoubleClick($event, item)">
                                <td v-for="column in getTableHeaders"
                                    class="c_table-td"
                                    v-bind:style="{width: getColumnWidth(column)}"
                                    v-bind:key="column.key">
                                    <div class="c_table-td-content-wrapper"
                                         v-bind:class="['m--align-' + column.alignment,
                                            { 'c_table-td-force-break': column.breakAnywhere }
                                        ]">
                                        <!-- Pass a template to the slot when using the component in order to control the cell content -->
                                        <slot v-bind:name="`cell(${column.key})`"
                                              v-bind:row="item">
                                            <!-- The default content will be a div with the item value related to the key -->
                                            <div v-bind:class="{
                                                     'c_table-td-copyable': column.copyable
                                                 }"
                                                 v-bind:title="column.copyable ? $tc('generals.copyToClipboard') : null"
                                                 v-on:click="column.copyable ? copyToClipboard($event, getValueByPropertyChain(column.key, item)) : null"
                                                 v-dompurify-html="getValueByPropertyChain(column.key, item)">
                                            </div>
                                        </slot>
                                    </div>
                                </td>
                                <td v-if="expandableRows"
                                    class="c_table-td"
                                    v-bind:style="{'max-width': `${expandColumnWidth}px`}">
                                    <div class="c_table-td-content-wrapper m--align-right m--border"
                                         v-bind:class="{'m--expanded': isRowExpanded(item)}">
                                        <IconButton
                                            v-bind:icon-class="isRowExpanded(item) ? 'fas fa-caret-down' : 'fas fa-caret-left'"
                                            v-bind:tooltip="isRowExpanded(item) ? $tc('table.collapseRow') : $tc('table.expandRow')"
                                            @button-submit="setRowExpanded(item)">
                                        </IconButton>
                                    </div>
                                </td>
                            </tr>
                            <transition name="show">
                                <div v-if="expandableRows && isRowExpanded(item)"
                                     class="c_table-row-expandable-content">
                                    <slot name="expandable-content"
                                          v-bind:row="item">
                                    </slot>
                                </div>
                            </transition>
                        </DynamicScrollerItem>
                    </template>
                </DynamicScroller>
            </tbody></table>
            <footer v-if="useTableFooter"
                    class="c_body-table-footer">
                <slot v-bind:name="`${tableId}_footer`"></slot>
            </footer>
        </div>

        <ColumnConfigModal :isExpanded="showColumnManipulationModal"
                           :columns="localUserTableManipulationSettings"
                           :tableConfig="tableConfig"
                           :settingsKey="getTableManipulationSettingsKey"
                           @close-modal="showColumnManipulationModal = false"
                           @columns-updated="updateLocalUserTableManipulationSettings"
        />
    </div>
</template>

<style lang="less">
.c_table-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    color: var(--color-text-mid);

    &:fullscreen {
        width: 100vw;
        height: 100vh;
        background-color: white;
        padding: var(--container-spacing);
    }

    .c_table-addon-wrapper {
        position: relative;
        width: 100%;
        height: var(--table-filter-height);

        .c_table-addons {
            position: relative;
            width: auto;
            height: 100%;

            .c_table-excel-generate-button {
                float: right;
                margin: 0 -4px 0 0;
            }

            .c_table-manipulate-columns-button {
                float: right;
                margin: 0 -4px 0 8px;
            }

            .c_table-full-screen-toggle {
                float: right;
                margin: 0 -4px 0 0;
            }

            .c_table-filter-container {
                float: right;
                position: relative;
                width: 0;
                margin-right: 30px;
                padding-top: 10px;

                .c_help-text {
                    height: auto;
                    max-height: 500px;
                    overflow-y: auto;

                    //Start: Styles for the help text that is stored as HTML structure in the i18n service
                    .c_table-filter-hint-headline {
                        font-family: "Source Sans Pro Bold", sans-serif;
                        font-weight: normal;
                        margin-bottom: 8px;
                    }

                    .c_table-filter-hint-paragraph {
                        padding: 0 8px;
                        margin-bottom: 8px;

                        &.m--addition {
                            margin-top: -8px;
                        }

                        .c_table-filter-hint-text-highlighted {
                            font-family: "Source Sans Pro Bold", sans-serif;
                            font-weight: normal;
                        }
                    }
                    // End ------------------------------------------------------------------------------
                }
            }

            .c_table-filter-button {
                float: right;
                margin-right: var(--container-spacing);
            }

            .c_table-filter-text {
                float: right;
            }
        }
    }

    .c_table {
        position: relative;
        width: 100%;
        border-collapse: collapse;
        table-layout: fixed;
        overflow-x: auto;
        max-width: 100%;

        .c_table-row {
            position: relative;
            width: 100%;
            border-bottom: 1px solid var(--color-border-dark);
            display: flex;

            .c_table-th {
                position: relative;
                height: var(--table-head-height);
                color: var(--color-text-mid);
                text-transform: uppercase;
                font-size: 17px;
                font-family: "Source Sans Pro Bold", sans-serif;
                text-align: start;
                cursor: default;
                user-select: none;
                display: flex;

                &.m--sort-asc,
                &.m--sort-desc,
                &.m--sort {
                    cursor: pointer;

                    span {
                        position: absolute;
                        left: 35px;
                    }
                }

                &.m--sort-asc,
                &.m--sort-desc {
                    color: var(--color-text-highlighted);
                }

                &.m--sort-asc:before,
                &.m--sort-desc:before,
                &.m--sort:before {
                    position: absolute;
                    left: 12px;
                    display: block;
                    outline: none;
                    font-family: "Font Awesome 5 Free", serif;
                    font-weight: 900;
                    top: 2px;
                }

                &.m--sort-asc:before {
                    content: "\f15d";
                    color: var(--color-icon-active);
                }

                &.m--sort-desc:before {
                    content: "\f881";
                    color: var(--color-icon-active);
                }

                &.m--sort:before {
                    content: "\f15d";
                    color: var(--color-text-light);
                }
            }
        }
    }

    .c_body-table-wrapper {
        position: relative;
        width: 100%;
        height: calc(100% - var(--table-head-height));

        &.m--filterable {
            height: calc(100% - var(--table-head-height) - var(--table-filter-height));
        }

        .c_table-filter-configuration-wrapper {
            width: 100%;
            height: 0;
            opacity: 0;
            display: flex;

            &.m--expanded {
                opacity: 1;
                border-bottom: 2px solid var(--color-border-light);
                height: var(--table-advanced-filter-height);
            }

            .c_table-filter-configuration-container {
                float: left;
                height: 100%;

                &:not(:first-child) {
                    border-left: 1px solid var(--color-border-light);
                }

                .c_table-filter-configuration {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    padding: 1px 10px 10px 10px;
                    overflow: hidden;

                    &.m--disabled {
                        padding-top: 14px;
                        background-color: var(--color-background-disabled);
                    }

                    .c_table-filter-configuration-header {
                        position: relative;
                        width: 100%;
                        height: var(--input-height);
                        overflow: hidden;

                        .c_table-filter-configuration-header-select {
                            width: 100%;

                            .generals-input-container {
                                width: 100%;

                                .generals-input {
                                    border-left: 0;
                                    border-right: 0;
                                    border-top: 0;
                                }
                            }
                        }
                    }

                    .c_table-filter-configuration-body {
                        position: relative;
                        width: 100%;
                        height: calc(100% - var(--input-height));
                        overflow-y: auto;
                        overflow-x: hidden;

                        .c_table-filter-configuration-body-badge {
                            cursor: default;
                            max-width: 100%;

                            span {
                                width: 100%;
                                display: inline-block;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }

                            button {
                                padding-top: 4px;
                            }
                        }
                    }
                }
            }
        }

        .c_body-table {
            position: relative;
            width: 100%;
            height: auto;
            border-spacing: 0;

            &.m--scrollable {
                height: 100%;
            }

            .c_body-table-placeholder {
                height: var(--table-row-height);
                text-align: center;
                display: block;
                padding-top: 14px;
                border-bottom: 1px solid var(--color-border-light);

                .c_body-table-placeholder-spinner {
                    margin-right: 5px;
                }
            }

            .c_body-table-scroll-container {
                position: relative;
                height: 100%;
                width: 100%;
                padding-bottom: 1px;
                overflow-y: auto;
                scroll-behavior: smooth;

                &.m--filter-expanded {
                    height: calc(100% - var(--table-advanced-filter-height));
                }

                .c_body-table-row {
                    width: 100%;
                    min-height: var(--table-row-height);
                    cursor: pointer;
                    display: flex;
                    border: 1px solid transparent;
                    border-bottom: 1px solid var(--color-border-light);

                    &.m--readOnly {
                        cursor: default;
                    }

                    &.m--selected {
                        border-color: var(--color-border-active);
                    }

                    &.m--disabled {
                        pointer-events: none;

                        .c_table-td {
                            opacity: 0.3;
                        }
                    }

                    &.m--expanded {
                        border-bottom: 0;
                    }

                    &:hover {
                        background-color: var(--color-background-hover-feedback);
                    }

                    .c_table-td {
                        padding: 6px 10px 8px 10px;
                        vertical-align: middle;
                        overflow-x: hidden;
                        display: flex;

                        .c_table-td-content-wrapper {
                            &.m--align-left {
                                text-align: -webkit-left;
                                float: left;
                            }

                            &.m--align-center {
                                text-align: -webkit-center;
                                text-align: center;
                            }

                            &.m--align-right {
                                text-align: -webkit-right;
                                float: right;
                            }

                            &.m--border {
                                padding-left: 10px;
                                border-left: 1px solid var(--color-border-light);

                                &.m--expanded {
                                    padding-left: 6px;
                                }
                            }

                            .c_table-td-copyable {
                                &:hover {
                                    color: var(--color-text-highlighted);
                                    cursor: pointer;
                                    &:after {
                                        position: absolute;
                                        margin: 4px 0 0 10px;
                                        content: "\f0c5";
                                        font-family: "Font Awesome 5 Free", serif;
                                        font-size: 15px;
                                        font-weight: 500;
                                    }
                                }
                            }

                        }
                        .c_table-td-force-break {
                            word-break: break-word;
                        }
                    }
                }

                .c_table-row-expandable-content {
                    width: 100%;
                    height: auto;
                    display: flex;
                    border-top: 1px dashed var(--color-border-light);
                    border-bottom: 1px solid var(--color-border-light);
                }
            }
        }
    }
}
</style>
