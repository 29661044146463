<script>
/**
 * Task list view for hidden tasks
 *
 **/
import Table from '@/components/Table.vue'
import RouteLink from '@/components/RouteLink.vue'
import IconButton from '@/components/IconButton.vue'
import { axiosService } from '@/mixins/axiosService'
import { userHandler } from '@/mixins/userHandler'
import { taskAssignmentHandler } from '@/mixins/taskAssignmentHandler'
import BadgeInput from '@/components/BadgeInput.vue'
import { dateTimeHelper } from '@/mixins/dateTimeHelper'
import InteractionLink from '@/components/InteractionLink.vue'
import Icon from '@/components/Icon.vue'

export default {
    name: 'HiddenTasksList',
    mixins: [
        axiosService,
        userHandler,
        taskAssignmentHandler,
        dateTimeHelper
    ],
    components: {
        Icon,
        InteractionLink,
        BadgeInput,
        Table,
        RouteLink,
        IconButton
    },
    data () {
        return {
            taskList: [],
            showSpinner: true
        }
    },
    methods: {
        getTaskList () {
            this.axiosGet(
                'tasks?taskVisibilityFilter=INVISIBLE_ONLY',
                this.$tc('taskList.getTaskListError'))
                .then(hiddenTasks => {
                    hiddenTasks.forEach(task => this.getTaskInformation(task))
                    this.taskList = hiddenTasks
                    this.showSpinner = false
                    this.sendTasksLengthChangedEvent()
                })
                .catch(() => {
                    this.showSpinner = false
                })
        },

        getTaskInformation (task) {
            task.assignmentDisplayText = this.getAssignmentDisplayText(task)
            task.briefingDatePlannedOrActual = task.replyDate || task.replyDeadline
            task.briefingDateDisplayText = this.getBriefingDateText(task)
            task.releaseDatePlannedOrActual = task.releaseDate || task.plannedReleaseDate
            task.releaseDateDisplayText = this.getReleaseDateText(task)
            task.creationDateDisplayText = this.getCreationDateText(task)

            // also remember original due date, as it has the correct format for sorting
            task.dueDate = this.getDueDate(task)
            task.dueDateDisplayText = this.getDueDateText(task)
        },
        // A task is considered "in progress" when a name is associated with the task, either in gray (...hadAnAssignee) or actively is working on a task with a blue-highlighted name(...hasAssignee).
        // The gray indication means that someone was working on the task, but is currently not actively doing so. They have unclaimed the task but still want to "reserve" it for future work.
        getTaskIsInProgress (task) {
            return this.getTaskHasOrHadAnAssignee(task)
        },

        getBriefingDateText (task) {
            const plannedOrActual = task.replyDate
                ? this.$tc('generals.actual')
                : this.$tc('generals.planned')
            return task.briefingDatePlannedOrActual
                ? `${this.getFormattedShortDate(task.briefingDatePlannedOrActual, this.locale)} (${plannedOrActual})`
                : null
        },

        getReleaseDateText (task) {
            const plannedOrActual = task.releaseDate
                ? this.$tc('generals.actual')
                : this.$tc('generals.planned')
            return task.releaseDatePlannedOrActual
                ? `${this.getFormattedShortDate(task.releaseDatePlannedOrActual, this.locale)} (${plannedOrActual})`
                : null
        },

        getCreationDateText (task) {
            return this.getFormattedLongDate(task.creationDate, this.locale)
        },

        getDueDate (content) {
            // return sortable turnaround date string, e.g. '2025-03-30'
            return content?.tatInfo?.turnaroundDateString
        },

        getDueDateText (content) {
            const dueDateString = this.getDueDate(content)
            return this.isDateRegex(dueDateString)
                ? this.getFormattedShortDate(dueDateString, this.locale)
                : dueDateString
        },
        hasDueDateIcon (content) {
            return this.isDateRegex(this.getDueDate(content))
        },

        isDueDateSetManually (content) {
            return content?.tatInfo?.isManuallySet
        },
        isOverdue (content) {
            return content?.tatInfo?.overdue
        },

        removeFromHiddenTasks (task) {
            this.axiosPut(
                `tasks/${task.id}/visible`,
                null,
                this.$tc('taskList.hidden.setTaskVisibleError'),
                {
                    standard: this.$tc('taskList.hidden.setTaskVisibleSuccess.standard'),
                    short: this.$tc('taskList.hidden.setTaskVisibleSuccess.short')
                })
                .then(() => {
                    const taskIds = this.taskList.map(_task => _task.id)
                    const index = taskIds.indexOf(task.id)
                    this.taskList.splice(index, 1)
                    this.sendTasksLengthChangedEvent()
                })
                .catch(() => {})
        },

        sendTasksLengthChangedEvent () {
            window.dispatchEvent(new CustomEvent('invisibleTasksCountChanged', { detail: this.taskList.length }))
        }
    },
    computed: {
        locale () {
            return this.$global.localization.locale
        },

        getTableConfig () {
            return [{
                key: 'id',
                label: null,
                filterable: true,
                sortable: false,
                alignment: 'left',
                width: 0,
                visible: false
            }, {
                key: 'name',
                label: this.$tc('taskList.table.taskName'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                breakAnyWhere: true,
                exportable: true,
                width: 15,
                visible: true
            }, {
                key: 'productNumber',
                label: this.$tc('taskList.table.productNumber'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 10,
                visible: true
            }, {
                key: 'isbn',
                label: this.$tc('taskList.table.isbn'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 10,
                visible: true
            }, {
                key: 'profitCenter',
                label: this.$tc('taskList.table.profitCenter'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 10,
                visible: false
            }, {
                key: 'productContextDescription',
                label: this.$tc('taskList.table.taskContext'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                breakAnyWhere: true,
                exportable: true,
                width: window.devicePixelRatio === 1.25 ? 15 : 20,
                visible: true
            }, {
                key: 'briefingDateDisplayText',
                sortKey: 'briefingDatePlannedOrActual',
                label: this.$tc('taskList.table.briefingDate'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 10,
                visible: true
            }, {
                key: 'releaseDateDisplayText',
                sortKey: 'releaseDatePlannedOrActual',
                label: this.$tc('taskList.table.releaseDate'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 10,
                visible: true
            }, {
                key: 'creationDateDisplayText',
                sortKey: 'creationDate',
                label: this.$tc('taskList.table.creationDate'),
                filterable: true,
                sortable: true,
                exportable: true,
                alignment: 'left',
                width: 10,
                visible: false
            }, {
                key: 'dueDateDisplayText',
                sortKey: 'dueDate',
                label: this.$tc('taskList.table.dueDate'),
                filterable: true,
                sortable: true,
                exportable: true,
                alignment: 'left',
                width: 10,
                visible: true
            }, {
                key: 'assignmentDisplayText',
                label: this.$tc('taskList.table.taskRole'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 15,
                visible: true
            }, {
                key: 'action',
                label: null,
                filterable: false,
                sortable: false,
                alignment: 'right',
                width: window.devicePixelRatio === 1.25 ? 15 : 10,
                visible: true
            }]
        }
    },
    watch: {
        '$global.localization.locale': {
            handler: function () {
                this.taskList.forEach(task => this.getTaskInformation(task))
            },
            deep: true
        }
    },
    created () {
        this.waitForPendingRequests.then(() => {
            this.getTaskList()
        })
    }
}
</script>

<template>
    <div class="generals-container">
        <div class="task-list_table-container">
            <Table table-id="hiddenTasksList"
                   v-bind:table-config="getTableConfig"
                   v-bind:table-data="taskList"
                   v-bind:filter-placeholder="$tc('taskList.table.filterPlaceholder')"
                   v-bind:table-empty-message="$tc('taskList.hidden.table.tableEmpty')"
                   v-bind:filter-no-results-message="$tc('taskList.table.filterNoResults')"
                   default-sorting-key="productNumber"
                   v-bind:default-filter-value="$route.params.filterValue"
                   v-bind:filter-disabled="!!$route.params.filterValue"
                   v-bind:show-spinner=showSpinner
                   v-bind:custom-user-filter="!$route.params.filterValue"
                   v-bind:read-only=true
                   v-bind:allow-fullscreen="true">
                <!-- name: default cell content -->
                <template #cell(productNumber)="data">
                    <RouteLink v-bind:displayed-text="data.row.productNumber"
                               v-bind:target="{name: 'Product', params: {productId: data.row.productId}}"
                               v-bind:tooltip="$tc('generals.goToProduct')"
                               v-bind:is-highlighted-link=true>
                    </RouteLink>
                </template>
                <!-- profitCenter: default cell content -->
                <!-- productContextDescription: default cell content -->
                <!-- briefingDateDisplayText: default cell content -->
                <!-- releaseDateDisplayText: default cell content -->
                <!-- creationDateDisplayText: default cell content -->
                <template #cell(dueDateDisplayText)="data">
                    <Icon v-if="hasDueDateIcon(data.row)"
                          class="task-list-table-due-date-icon"
                          v-bind:icon-class="isDueDateSetManually(data.row) ? 'fas fa-user-edit' : 'fas fa-calculator'">
                    </Icon>
                    <InteractionLink class="generals-table-input"
                                     v-bind:value=getDueDateText(data.row)
                                     v-bind:text-color-class="isOverdue(data.row) ? 'error' : ''">
                    </InteractionLink>
                </template>
                <template #cell(assignmentDisplayText)="data">
                    <BadgeInput v-if="getTaskIsInProgress(data.row)"
                                v-bind:is-disabled=true
                                v-bind:value="data.row.assignmentDisplayText"
                                class="task-list-table-assignee-badge"
                                v-bind:class="{'m--currently-in-progress': data.row.assignee}">
                    </BadgeInput>
                    <span v-else>{{data.row.assignmentDisplayText}}</span>
                </template>
                <template #cell(action)="data">
                    <IconButton icon-class="fas fa-eye"
                                v-bind:tooltip="$tc('taskList.hidden.table.showTask')"
                                @button-submit="removeFromHiddenTasks(data.row)">
                    </IconButton>
                </template>
            </Table>
        </div>
    </div>
</template>

<style scoped lang="less">
.task-list_table-container {
    width: 100%;
    height: 100%;
    padding: 0 var(--container-spacing) var(--container-spacing) var(--container-spacing);

    .task-list-table-due-date-icon {
        display: inline-block;
        margin: 4px 4px 0 -8px;
    }
}
</style>
