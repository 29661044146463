<script>
/**
 * Task list view
 *
 * @property {Boolean} isTaskExecutable
 **/
import Table from '@/components/Table.vue'
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import RouteLink from '@/components/RouteLink.vue'
import BadgeInput from '@/components/BadgeInput.vue'
import IconButton from '@/components/IconButton.vue'
import VerticalLine from '@/components/VerticalLine.vue'
import { axiosService } from '@/mixins/axiosService'
import { userHandler } from '@/mixins/userHandler'
import { taskAssignmentHandler } from '@/mixins/taskAssignmentHandler'
import { dateTimeHelper } from '@/mixins/dateTimeHelper'
import InteractionLink from '@/components/InteractionLink.vue'

export default {
    name: 'TaskList',
    mixins: [
        axiosService,
        userHandler,
        taskAssignmentHandler,
        dateTimeHelper
    ],
    components: {
        InteractionLink,
        Table,
        Button,
        Icon,
        RouteLink,
        BadgeInput,
        IconButton,
        VerticalLine
    },
    data () {
        return {
            taskList: [],
            refreshEverySeconds: 10,
            refreshCounter: null,
            showSpinner: true
        }
    },
    methods: {
        getTaskList () {
            this.axiosGet(
                'tasks?taskVisibilityFilter=VISIBLE_ONLY',
                this.$tc('taskList.getTaskListError'))
                .then(taskList => {
                    taskList.forEach(task => this.getTaskInformation(task))
                    this.taskList = taskList
                    this.showSpinner = false
                    this.setStopWatch()
                    this.sendTasksLengthChangedEvent()
                })
                .catch(() => {
                    this.showSpinner = false
                })
        },

        getTaskInformation (task) {
            task.assignmentDisplayText = this.getAssignmentDisplayText(task)
            task.isTaskExecutableFilterText = this.getTaskExecutableFilterText(task)
            task.briefingDatePlannedOrActual = task.replyDate || task.replyDeadline
            task.briefingDateDisplayText = this.getBriefingDateText(task)
            task.releaseDatePlannedOrActual = task.releaseDate || task.plannedReleaseDate
            task.releaseDateDisplayText = this.getReleaseDateText(task)
            task.creationDateDisplayText = this.getCreationDateText(task)

            // also remember original due date, as it has the correct format for sorting
            task.dueDate = this.getDueDate(task)
            task.dueDateDisplayText = this.getDueDateText(task)
        },

        setStopWatch () {
            this.refreshCounter = this.refreshEverySeconds
            this.refreshInterval = setInterval(() => {
                if (this.refreshCounter > 0) {
                    this.refreshCounter--
                } else {
                    clearInterval(this.refreshInterval)
                    this.getTaskList()
                }
            }, 1000)
        },

        claimTask (task, openTask) {
            if (task.isTaskExecutable) {
                this.assignTask(task, openTask)
            } else {
                this.addCursorNotification({
                    type: 'info',
                    duration: 5000,
                    message: this.$tc('taskList.taskNotProcessable')
                })
            }
        },

        getTaskExecutableFilterText (task) {
            return this.$tc(`taskList.table.tasksFor${task.isTaskExecutable ? 'Me' : 'Others'}`)
        },

        getBriefingDateText (task) {
            const plannedOrActual = task.replyDate
                ? this.$tc('generals.actual')
                : this.$tc('generals.planned')
            return task.briefingDatePlannedOrActual
                ? `${this.getFormattedShortDate(task.briefingDatePlannedOrActual, this.locale)} (${plannedOrActual})`
                : null
        },

        getReleaseDateText (task) {
            const plannedOrActual = task.releaseDate
                ? this.$tc('generals.actual')
                : this.$tc('generals.planned')
            return task.releaseDatePlannedOrActual
                ? `${this.getFormattedShortDate(task.releaseDatePlannedOrActual, this.locale)} (${plannedOrActual})`
                : null
        },

        getCreationDateText (task) {
            return this.getFormattedLongDate(task.creationDate, this.locale)
        },

        getDueDate (content) {
            // return sortable turnaround date string, e.g. '2025-03-30'
            return content?.tatInfo?.turnaroundDateString
        },

        getDueDateText (content) {
            const dueDateString = this.getDueDate(content)
            return this.isDateRegex(dueDateString)
                ? this.getFormattedShortDate(dueDateString, this.locale)
                : dueDateString
        },

        hasDueDateIcon (content) {
            return this.isDateRegex(this.getDueDate(content))
        },

        isDueDateSetManually (content) {
            return content?.tatInfo?.isManuallySet
        },

        getIconType (task) {
            return this.getAssignmentIconType(task)
        },

        getIconClass (task) {
            return this.getAssignmentIconClass(task)
        },

        getIconTooltip (task) {
            return this.getAssignmentIconTooltip(task)
        },

        getIconIsGlowing (task) {
            return this.getTaskIsRelatedToCurrentUser(task)
        },
        // A task is considered "in progress" when a name is associated with the task, either in gray (...hadAnAssignee) or actively is working on a task with a blue-highlighted name(...hasAssignee).
        // The gray indication means that someone was working on the task, but is currently not actively doing so. They have unclaimed the task but still want to "reserve" it for future work.
        getTaskIsInProgress (task) {
            return this.getTaskHasOrHadAnAssignee(task)
        },

        addToHiddenTasks (task) {
            this.axiosPut(
                `tasks/${task.id}/invisible`,
                null,
                this.$tc('taskList.visible.setTaskHiddenError'),
                {
                    standard: this.$tc('taskList.visible.setTaskHiddenSuccess.standard'),
                    short: this.$tc('taskList.visible.setTaskHiddenSuccess.short')
                })
                .then(() => {
                    const taskIds = this.taskList.map(_task => _task.id)
                    const index = taskIds.indexOf(task.id)
                    this.taskList.splice(index, 1)
                    this.sendTasksLengthChangedEvent()
                })
                .catch(() => {})
        },

        isTaskExecutable (task) {
            const currentUser = this.getUserName()
            return !task.waitingForSignalRequests && task.isTaskExecutable && (!task.assignee || task.assignee === currentUser)
        },

        backToTotalTaskList () {
            this.$router.push({ name: 'TaskList' })
        },

        sendTasksLengthChangedEvent () {
            window.dispatchEvent(new CustomEvent('visibleTasksCountChanged', { detail: this.taskList.length }))
        },

        isOverdue (content) {
            return content.tatInfo && content.tatInfo.overdue
        }
    },
    computed: {
        locale () {
            return this.$global.localization.locale
        },

        getTableConfig () {
            return [{
                key: 'id',
                label: null,
                filterable: true,
                sortable: false,
                alignment: 'left',
                width: 0,
                visible: false
            }, {
                key: 'status',
                label: null,
                filterable: false,
                sortable: false,
                alignment: 'left',
                width: 5,
                visible: true
            }, {
                key: 'name',
                label: this.$tc('taskList.table.taskName'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                breakAnyWhere: true,
                exportable: true,
                width: 15,
                visible: true
            }, {
                key: 'productNumber',
                label: this.$tc('taskList.table.productNumber'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 10,
                visible: true
            }, {
                key: 'isbn',
                label: this.$tc('taskList.table.isbn'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 10,
                visible: true
            }, {
                key: 'profitCenter',
                label: this.$tc('taskList.table.profitCenter'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 10,
                visible: false
            }, {
                key: 'productContextDescription',
                label: this.$tc('taskList.table.taskContext'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                breakAnyWhere: true,
                exportable: true,
                width: window.devicePixelRatio === 1.25 ? 10 : 15,
                visible: true
            }, {
                key: 'briefingDateDisplayText',
                sortKey: 'briefingDatePlannedOrActual',
                label: this.$tc('taskList.table.briefingDate'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 10,
                visible: false
            }, {
                key: 'releaseDateDisplayText',
                sortKey: 'releaseDatePlannedOrActual',
                label: this.$tc('taskList.table.releaseDate'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 10,
                visible: false
            }, {
                key: 'creationDateDisplayText',
                sortKey: 'creationDate',
                label: this.$tc('taskList.table.creationDate'),
                filterable: true,
                sortable: true,
                exportable: true,
                alignment: 'left',
                width: 10,
                visible: true
            }, {
                key: 'dueDateDisplayText',
                sortKey: 'dueDate',
                label: this.$tc('taskList.table.dueDate'),
                filterable: true,
                sortable: true,
                exportable: true,
                alignment: 'left',
                width: 10,
                visible: true
            }, {
                key: 'assignmentDisplayText',
                label: this.$tc('taskList.table.taskRole'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 15,
                visible: true
            }, {
                key: 'action',
                filterKey: 'isTaskExecutableFilterText',
                label: null,
                filterable: true,
                sortable: false,
                alignment: 'right',
                width: window.devicePixelRatio === 1.25 ? 15 : 10,
                visible: true
            }]
        },

        getStopWatchIcon () {
            if (this.refreshCounter > this.refreshEverySeconds * 0.5) {
                return 'fas fa-hourglass-start'
            } else if (this.refreshCounter <= this.refreshEverySeconds * 0.1) {
                return 'fas fa-hourglass-end'
            }
            return 'fas fa-hourglass-half'
        },

        getStopWatchType () {
            return this.refreshCounter === 0 ? 'info' : 'default'
        },

        getStopWatchNull () {
            return this.refreshCounter === 0
        },

        getStopWatchTooltip () {
            return this.$t('taskList.visible.timeUntilRefresh', [this.refreshCounter])
        },

        currentFilterValue () {
            if (this.$route.query?.filterValue) {
                // Keycloak JS causes trouble in vue router4 mode webHashHistory together with url parameters
                // see https://stackoverflow.com/questions/73903119/keycloak-js-appending-session-data-to-url-in-vue3
                //
                // FIX/HACK explicitly remove the keycloak states from the filterValue
                return this.$route.query?.filterValue.replace(/&*state=.*$/g, '')
            }
            return undefined
        }
    },
    watch: {
        '$global.localization.locale': {
            handler: function () {
                this.taskList.forEach(task => this.getTaskInformation(task))
            },
            deep: true
        }
    },
    created () {
        this.waitForPendingRequests.then(() => {
            this.getTaskList()
        })
    },
    beforeUnmount () {
        clearInterval(this.refreshInterval)
    }
}
</script>

<template>
    <div class="generals-container">
        <div class="task-list_table-container">
            <Button v-if="!!currentFilterValue"
                    class="task-list-return-button"
                    button-type="cancel"
                    @button-submit="backToTotalTaskList()">
                <template v-slot>
                    <span>{{$tc('taskList.visible.backToTotalTaskList')}}</span>
                </template>
            </Button>
            <Icon class="task-list-table-refresh-icon"
                  v-bind:class="{'m--refresh': getStopWatchNull}"
                  v-bind:icon-type="getStopWatchType"
                  v-bind:icon-class="getStopWatchIcon"
                  v-bind:tooltip="getStopWatchTooltip"
                  v-bind:icon-large=true>
            </Icon>
            <Table table-id="taskList"
                   v-bind:key="currentFilterValue"
                   v-bind:table-config="getTableConfig"
                   v-bind:table-data="taskList"
                   v-bind:filter-placeholder="$tc('taskList.table.filterPlaceholder')"
                   v-bind:table-empty-message="$tc('taskList.visible.table.tableEmpty')"
                   v-bind:filter-no-results-message="$tc('taskList.table.filterNoResults')"
                   default-sorting-key="productNumber"
                   v-bind:default-filter-value="currentFilterValue"
                   v-bind:filter-disabled="!!currentFilterValue"
                   v-bind:show-spinner=showSpinner
                   v-bind:custom-user-filter="!currentFilterValue"
                   v-bind:read-only="false"
                   v-bind:allow-fullscreen="true"
                   @row-double-click="claimTask($event.row, true)">
                <template #cell(status)="data">
                    <Icon v-bind:icon-type="getIconType(data.row)"
                          v-bind:icon-class="getIconClass(data.row)"
                          v-bind:tooltip="getIconTooltip(data.row)"
                          v-bind:icon-glow="getIconIsGlowing(data.row)">
                    </Icon>
                </template>
                <!-- name: default cell content -->
                <template #cell(productNumber)="data">
                    <RouteLink v-bind:displayed-text="data.row.productNumber"
                               v-bind:target="{name: 'Product', params: {productId: data.row.productId}}"
                               v-bind:tooltip="$tc('generals.goToProduct')"
                               v-bind:is-highlighted-link=true>
                    </RouteLink>
                </template>
                <!-- profitCenter: default cell content -->
                <!-- productContextDescription: default cell content -->
                <!-- briefingDateDisplayText: default cell content -->
                <!-- releaseDateDisplayText: default cell content -->
                <!-- creationDateDisplayText: default cell content -->
                <template #cell(dueDateDisplayText)="data">
                    <Icon v-if="hasDueDateIcon(data.row)"
                          class="task-list-table-due-date-icon"
                          v-bind:icon-class="isDueDateSetManually(data.row) ? 'fas fa-user-edit' : 'fas fa-calculator'">
                    </Icon>
                    <InteractionLink class="generals-table-input"
                                     v-bind:value=getDueDateText(data.row)
                                     v-bind:text-color-class="isOverdue(data.row) ? 'error' : ''">
                    </InteractionLink>
                </template>
                <template #cell(assignmentDisplayText)="data">
                    <BadgeInput v-if="getTaskIsInProgress(data.row)"
                                v-bind:is-disabled=true
                                v-bind:value="data.row.assignmentDisplayText"
                                class="task-list-table-assignee-badge"
                                v-bind:class="{'m--currently-in-progress': data.row.assignee}">
                    </BadgeInput>
                    <span v-else>{{data.row.assignmentDisplayText}}</span>
                </template>
                <template #cell(action)="data">
                    <IconButton icon-class="fas fa-eye-slash"
                                v-bind:tooltip="$tc('taskList.visible.table.hideTask')"
                                @button-submit="addToHiddenTasks(data.row)">
                    </IconButton>
                    <VerticalLine></VerticalLine>
                    <IconButton v-bind:icon-class="data.row.waitingForSignalRequests ? 'fas fa-spin fa-circle-notch' : 'fas fa-play-circle'"
                                v-bind:tooltip="$tc('taskList.table.claimAndEditTask')"
                                v-bind:is-disabled="!isTaskExecutable(data.row)"
                                @button-submit="claimTask(data.row, true)">
                    </IconButton>
                </template>
            </Table>
        </div>
    </div>
</template>

<style lang="less">
.task-list_table-container {
    width: 100%;
    height: 100%;
    padding: 0 var(--container-spacing) var(--container-spacing) var(--container-spacing);

    .task-list-return-button {
        position: absolute;
        z-index: var(--z-index-table-overlay);
    }

    .task-list-table-refresh-icon {
        position: absolute;
        top: 75px;
        left: 25px;
        z-index: var(--z-index-table-overlay);
        width: 35px;
        height: 35px;
        margin-top: -12px;
        padding-top: 4px;
        border-radius: 50%;

        &.m--refresh {
            padding-top: 3px;
            border: 1px solid var(--color-info);
        }
    }

    .task-list-table-due-date-icon {
        display: inline-block;
        margin: 4px 4px 0 -8px;
    }

    .task-list-table-assignee-badge {
        margin: 0;

        &.m--currently-in-progress {
            background-color: var(--color-info);
            color: var(--color-text-bright);
        }
    }

    .task-list-table-select {
        position: relative;
        width: 100%;

        .generals-input-container {
            width: unset;
        }
    }
}
</style>
