<script>
/**
 * Task view
 * This view serves as a wrapper for the different structured task views.
 * The view consists of a static header with a navigation option to the task list, the name of the task and the possibility to complete or return it.
 * In addition, there always is a fold-out overlay with the task description.
 * Last but not least, it offers a container into which various task components can be loaded.
 * Which component is rendered is determined by the value of the 'formKey' property, which is part of the task definition. If none is provided, the DefaultTask.vue view will be used.
 *
 * When implementing additional components, the following must be taken to account:
 * 0. Create a new component within path 'src/views/tasks/taskViews/' and give it a descriptive name.
 * 1. Import your component in the import section of this file.
 * 2. Define a new and unique customFormKey and add it to the dictionary 'componentKeys' in this file.
 *
 * Important: Make sure your new component will emit a 'task-completable-changed' event whenever the state for finishing the task changes.
 * This will let the task know whether the button to finish the task has to be enabled or disabled.
 * For this purpose you will have to define a computed function which will return either true or false based on this condition.
 * Furthermore, you will need a watcher that observes your computed function and automatically emits the updated state as soon as the return value changes.
 * Just have a look at the 'CustomTaskExample.vue'. You can copy and paste the computed function and the watcher and then edit the condition of the 'isTaskCompletable' function to make it work.
 * You do NOT have to change the logic of the watcher, as it will exactly do what it is meant to.
 * Hint: See the comments in this file for further assistance.
 *
 * Now, implement whatever you need for your specific task view, and you're good to go.
 *
 * @property {String} productShortTitle
 * @property {Boolean} notificationTaskTermination
 * @property {String} customFormKey
 **/
import ContentHeader from '@/components/ContentHeader.vue'
import Button from '@/components/Button.vue'
import PageOverlay from '@/components/PageOverlay.vue'
import DataRow from '@/components/DataRow.vue'
import DefaultTask from '@/views/tasks/taskViews/DefaultTask.vue'
import CustomTaskExample from '@/views/tasks/taskViews/CustomTaskExample.vue'
import CustomTaskTitleDoc from '@/views/tasks/taskViews/CustomTaskTitleDoc.vue'
import CustomTaskBriefing from '@/views/tasks/taskViews/CustomTaskBriefing.vue'
import CustomTaskCommissioning from '@/views/tasks/taskViews/CustomTaskCommissioning.vue'
// 1.a. Import your new custom component -> import MyCustomTaskWithDescriptiveName from '@/views/tasks/taskViews/MyCustomTaskWithDescriptiveName.vue'
import { axiosService } from '@/mixins/axiosService'
import { userHandler } from '@/mixins/userHandler'
import { taskAssignmentHandler } from '@/mixins/taskAssignmentHandler'
import { customFormKeyHandler } from '@/mixins/customFormKeyHandler'
import CustomTaskEinreichungEssential from '@/views/tasks/taskViews/CustomTaskEinreichungEssential.vue'
import CustomTaskManuscriptProvisioning from '@/views/tasks/taskViews/CustomTaskManuscriptProvisioning.vue'
import CustomTaskTypesettingAndCorrections from '@/views/tasks/taskViews/CustomTaskTypesettingAndCorrections.vue'
import CustomTaskTypesettingCheck from '@/views/tasks/taskViews/CustomTaskTypesettingCheck.vue'
import CustomTaskFinalPdf from '@/views/tasks/taskViews/CustomTaskFinalPdf.vue'
import CustomTaskConclusionSurvey from '@/views/tasks/taskViews/CustomTaskConclusionSurvey.vue'
import CustomTaskSpecifyPublicationData from '@/views/tasks/taskViews/CustomTaskSpecifyPublicationData.vue'
import CustomTaskProcessDataWithoutCorrection from '@/views/tasks/taskViews/CustomTaskProcessDataWithoutCorrection.vue'
import CustomTaskPublicationDataCreation from '@/views/tasks/taskViews/CustomTaskPublicationDataCreation.vue'
import CustomTaskPerformEinreichung from '@/views/tasks/taskViews/CustomTaskPerformEinreichung.vue'

export default {
    name: 'Task',
    mixins: [
        axiosService,
        userHandler,
        taskAssignmentHandler,
        customFormKeyHandler
    ],
    components: {
        ContentHeader,
        Button,
        PageOverlay,
        DataRow,
        DefaultTask,
        CustomTaskExample,
        CustomTaskTitleDoc,
        CustomTaskBriefing,
        CustomTaskCommissioning,
        CustomTaskEinreichungEssential,
        CustomTaskManuscriptProvisioning,
        CustomTaskTypesettingAndCorrections,
        CustomTaskTypesettingCheck,
        CustomTaskFinalPdf,
        CustomTaskConclusionSurvey,
        CustomTaskSpecifyPublicationData,
        CustomTaskProcessDataWithoutCorrection,
        CustomTaskPublicationDataCreation,
        CustomTaskPerformEinreichung
        // 1.b. Add your new custom component -> MyCustomTaskWithDescriptiveName
    },
    data () {
        return {
            taskId: this.$route.params.taskId,
            task: {
                productId: 0 // Default value to avoid error in ContentHeader#RouteLink. Will be updated there in 'getTask()'
            },
            unClaimTaskOnDestroy: true, // Used for debouncing: If false, the task is already being unclaimed -> necessary, because finishing a task unclaims it as well.
            navigationOrigin: null,
            taskDescriptionOverlayName: 'task-description',
            isTaskCompletable: false,
            componentKeys: {
                // componentKey is the same formKey as in BPMN and back end without parameters
                // samples
                // - BPMN+BE: CustomKeyForMyComponent => componentKey: CustomKeyForMyComponent
                // - BPMN: CustomKeyForMyComponent|${param1}|${param2}
                //   + BE: customFormKey: CustomKeyForMyComponent + additionalParameter: param1|param2 => componentKey: CustomKeyForMyComponent
                Default: 'DefaultTask',
                CustomTest: 'CustomTaskExample',
                CustomTitelDokuForm: 'CustomTaskTitleDoc',
                PreBriefingForm: 'CustomTaskBriefing',
                FinalBriefingForm: 'CustomTaskBriefing',
                IllustratorsForm: 'CustomTaskCommissioning',
                PhotographersForm: 'CustomTaskCommissioning',
                PrepareEinreichung: 'CustomTaskEinreichungEssential',
                CommunicateResultEinreichung: 'CustomTaskEinreichungEssential',
                CommunicateResultResubmission: 'CustomTaskEinreichungEssential',
                ArrangeEinreichung: 'CustomTaskEinreichungEssential',
                PerformEinreichung: 'CustomTaskPerformEinreichung',
                ArrangeResubmission: 'CustomTaskEinreichungEssential',
                PerformResubmission: 'CustomTaskEinreichungEssential',
                ProvideManuskriptSatzprozess: 'CustomTaskManuscriptProvisioning',
                TypesettingAndCorrections: 'CustomTaskTypesettingAndCorrections',
                CheckTypesetting: 'CustomTaskTypesettingCheck',
                FinalPdf: 'CustomTaskFinalPdf',
                ConclusionSurvey: 'CustomTaskConclusionSurvey',
                SpecifyPublicationData: 'CustomTaskSpecifyPublicationData',
                ProcessDataWithoutCorrection: 'CustomTaskProcessDataWithoutCorrection',
                PublicationDataCreation: 'CustomTaskPublicationDataCreation'
                // 2. Add your new custom component key -> CustomKeyForMyComponent: 'CustomComponentName'
            }
        }
    },
    methods: {
        getTask () {
            const isValueValid = this.taskId && !this.taskId.match(/state=|session_state=|code=/)

            if (isValueValid) {
                this.axiosGet(
                    `task/${this.taskId}`,
                    this.$tc('task.getTaskInfoError'))
                    .then(task => {
                        this.task = task
                    })
                    .catch(() => {})
            }
        },
        setTaskCompletable (taskCompletableChangedEventData) {
            this.isTaskCompletable = taskCompletableChangedEventData.isCompletable
        },
        unClaimTaskWithoutTrace () {
            this.unAssignTask(this.task, false, true)
                .then(() => {
                    this.unClaimTaskOnDestroy = false
                    this.$router.replace({ name: this.navigationOrigin, params: { productId: this.task.productId } })
                })
                .catch(() => {})
        },

        async unClaimTask () {
            if (this.unClaimTaskOnDestroy) {
                this.unClaimTaskOnDestroy = false
                await this.unAssignTask(this.task, true)
            }
        },

        finishTask (triggeredByTimer) {
            // Closures to simplify code structure
            function delayTaskTermination (appContext) {
                const taskTerminationTimeout = setTimeout(() => {
                    window.dispatchEvent(new Event('delayTaskTerminationExpired'))
                    appContext.finishTask(true)
                }, 10000)
                appContext.unClaimTaskOnDestroy = false
                window.dispatchEvent(new CustomEvent('delayTaskTermination', {
                    detail: {
                        task: appContext.task,
                        timeout: taskTerminationTimeout
                    }
                }))
                appContext.$router.replace({
                    name: appContext.navigationOrigin,
                    params: { productId: appContext.task.productId }
                })
            }

            const userPreferences = this.getUserSettingsParameter('userPreferences')
            if (!triggeredByTimer && userPreferences.notificationTaskTermination) {
                delayTaskTermination(this)
            } else {
                this.axiosPost(
                    `task/${this.taskId}/complete`,
                    null,
                    this.$tc('task.completeTaskError'),
                    {
                        standard: this.$tc('task.completeTaskSuccess.standard'),
                        short: this.$tc('task.completeTaskSuccess.short')
                    })
                    .then(() => {
                        this.unClaimTaskOnDestroy = false
                        if (!triggeredByTimer) {
                            this.$router.replace({
                                name: this.navigationOrigin,
                                params: { productId: this.task.productId }
                            })
                        }
                    })
                    .catch(() => {
                    })
            }
        }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if (from.name) {
                vm.navigationOrigin = from.name
            }
        })
    },
    created () {
        // see https://router.vuejs.org/guide/advanced/data-fetching.html#Fetching-After-Navigation
        this.$watch(
            () => this.$route.params.taskId,
            () => this.getTask(),
            // fetch the data when the view is created and the data is
            // already being observed
            { immediate: true }
        )
    },
    beforeMount () {
        window.addEventListener('beforeunload', this.unClaimTask)
    },
    beforeUnmount () {
        this.unClaimTask()
        window.removeEventListener('beforeunload', this.unClaimTask)
    }
}
</script>

<template>
    <div class="generals-container">
        <!-- Static content header for each task -->
        <ContentHeader v-if="navigationOrigin"
                       v-bind:header-title="task.productNumber"
                       v-bind:header-sub-title="task.name"
                       v-bind:link-config="{target: {name: 'Product', params: {productId: task.productId}}, tooltip: $tc('generals.goToProduct')}"
                       v-bind:go-back-target="{name: navigationOrigin, title: $tc(`task.originTitles.${navigationOrigin}`), params: {productId: task.productId}}"
                       v-bind:is-primary-header=true
                       v-bind:use-slot="true">
            <template v-slot>
                <Button button-type="submit"
                        v-bind:is-disabled="!isTaskCompletable"
                        @button-submit="finishTask()">
                    <template v-slot>
                        <span>{{$tc('task.finishTask')}}</span>
                    </template>
                </Button>
                <Button button-type="cancel"
                        @button-submit="unClaimTaskWithoutTrace()">
                    <template v-slot>
                        <span>{{$tc('task.returnTask')}}</span>
                    </template>
                </Button>
            </template>
        </ContentHeader>
        <template v-if="task.id">
            <div class="task-content-container"
                 v-bind:key="taskId">
                <component v-bind:is="componentKeys[componentKeyBy(task.formKey)] || componentKeys.Default"
                           v-bind:task-data="task"
                           @task-completable-changed="setTaskCompletable($event)">
                </component>
            </div>
            <!-- Static page overlay with generic information about the task -->
            <PageOverlay v-bind:slot-specifier="taskDescriptionOverlayName"
                         v-bind:collapsed-text="$tc('task.taskDescription.expandDescription')"
                         v-bind:expanded-text="$tc('task.taskDescription.collapseDescription')"
                         v-bind:use-header=true
                         header-size="large">
                <template v-slot:[`${taskDescriptionOverlayName}_overlay-header`]>
                    <DataRow v-bind:label="$tc('task.taskDescription.context')"
                             v-bind:value="task.productContextDescription"
                             v-bind:no-line-break=true
                             v-bind:label-width-percentage=20>
                    </DataRow>
                    <DataRow v-bind:label="$tc('task.taskDescription.taskName')"
                             v-bind:value="task.name"
                             v-bind:no-line-break=true
                             v-bind:label-width-percentage=20>
                    </DataRow>
                    <DataRow v-bind:label="$tc('task.taskDescription.product')"
                             v-bind:value="`${task.productNumber} - ${task.productShortTitle}`"
                             v-bind:no-line-break=true
                             v-bind:label-width-percentage=20>
                    </DataRow>
                </template>
                <template v-slot:[`${taskDescriptionOverlayName}_overlay`]>
                    <div class="task-description-body"
                         v-dompurify-html="task.description">
                    </div>
                </template>
            </PageOverlay>
        </template>
    </div>
</template>

<style scoped lang="less">
.task-content-container {
    width: 100%;
    height: calc(100% - var(--primary-headline-height) - var(--page-overlay-collapsed-height));
    padding: var(--container-spacing);
    overflow: hidden;
}
</style>
